export const LEAD_NAME = 'Leads Name';
export const LEAD_AGENCY = 'Agency';
export const LEAD_OWNER = 'Leads Owner';

export const GET_ARCHIVED_LEADS = 'GET_ARCHIVED_LEADS';
export const GET_ARCHIVED_LEADS_SUCCESS = 'GET_ARCHIVED_LEADS_SUCCESS';
export const GET_ARCHIVED_LEADS_FAILURE = 'GET_ARCHIVED_LEADS_FAILURE';
export const GET_ARCHIVED_LEADS_OWNER_FAILURE = 'GET_ARCHIVED_LEADS_OWNER_FAILURE';
export const GET_ARCHIVED_LEADS_OWNER_SUCCESS = 'GET_ARCHIVED_LEADS_OWNER_SUCCESS';

export const UNARCHIVE_LEADS = 'UNARCHIVE_LEADS';
export const UNARCHIVE_LEADS_SUCCESS = 'UNARCHIVE_LEADS_SUCCESS';
export const UNARCHIVE_LEADS_FAILURE = 'UNARCHIVE_LEADS_FAILURE';

export const UPDATE_FILTER_ARCHIVED_LEADS = 'UPDATE_FILTER_ARCHIVED_LEADS';

export type leadsType = {
  data: any;
};

export interface GetArchivedLeads {
  type: typeof GET_ARCHIVED_LEADS;
}

export interface GetArchivedLeadsSuccess {
  type: typeof GET_ARCHIVED_LEADS_SUCCESS;
  payload: leadsType;
}

export interface GetArchivedLeadsFailure {
  type: typeof GET_ARCHIVED_LEADS_FAILURE;
}

export interface GetArchivedLeadOwnerSuccess {
  type: typeof GET_ARCHIVED_LEADS_OWNER_SUCCESS;
}

export interface GetArchivedLeadOwnerFailure {
  type: typeof GET_ARCHIVED_LEADS_OWNER_FAILURE;
}

export interface UnarchiveLeads {
  type: typeof UNARCHIVE_LEADS;
}

export interface UnarchiveLeadsSuccess {
  type: typeof UNARCHIVE_LEADS_SUCCESS;
  payload: any;
}

export interface UnarchiveLeadsFailure {
  type: typeof UNARCHIVE_LEADS_FAILURE;
  message: string;
}

export interface UpdateFilterArchivedLeads {
  type: typeof UPDATE_FILTER_ARCHIVED_LEADS;
  payload: any;
}

export type ArchivedLeadsDispatchTypes =
  | GetArchivedLeads
  | GetArchivedLeadsFailure
  | GetArchivedLeadsSuccess
  | GetArchivedLeadOwnerFailure
  | GetArchivedLeadOwnerSuccess
  | UnarchiveLeads
  | UnarchiveLeadsSuccess
  | UnarchiveLeadsFailure
  | UpdateFilterArchivedLeads;
