export const CATEGORY_PRODUCT_MEMBERSHIP = '1';
export const CATEGORY_PRODUCT_ALACARTE = '2';
export const CATEGORY_PRODUCT_UPGRADE = '3';
export const FILTER_CATEGORY = 'category';
export const FILTER_TYPE = 'filter_type';
export const PAGE_SETTING_PRODUCT = 'product';
export const UPGRADE_SETTING_SEARCH_ADDONS = 'platinum Add-On';
export const CATEGORY_PRODUCT = [
  {
    id: '1',
    name: 'Membership',
    label: 'membership',
  },
  {
    id: '2',
    name: 'Ala Carte',
    label: 'alacarte',
  },
  {
    id: '3',
    name: 'Upgrade',
    label: 'upgrade',
  },
];

export const PURCHASE_TYPE_INDIVIDUAL = 1;
export const PURCHASE_TYPE_CORPORATE = 2;
export const PURCHASE_TYPE_BULK = 3;
export const PAGE_PRODUCT = 'product';
export const PAGE_UPGRADE_ADD = 'upgrade-add';
export const PAGE_UPGRADE_EDIT = 'upgrade-edit';
export const SETTING_TYPE_PRODUCT = 'product';
export const SETTING_TYPE_PRODUCT_DISCOUNT = 'discount';
export const SETTING_TYPE_PRODUCT_MEMBERSHIP = 'membership';
export const SETTING_TYPE_PRODUCT_ALACARTE = 'alacarte';
export const SETTING_TYPE_PRODUCT_UPGRADE = 'upgrade';
export const SETTING_TYPE_DISCOUNT = 'discount';
export const UPGRADE_TYPE_UPGRADE_PACKAGE = 2;
export const UPGRADE_TYPE_UPGRADE_ADD_ONS = 3;
export const SHOW_DEFAULT_DISCOUNT_UPGRADE = 2;
export const MAX_UPLOAD_FILE = 5000000;
export const SETTING_TYPE_PRODUCT_UPGRADE_PACKAGE = 1;
export const SETTING_TYPE_PRODUCT_UPGRADE_ADDONS = 2;
export const SETTING_TYPE_SUBCATEGORY_UPGRADE = [
  {
    id: 1,
    value: 'Upgrade Packages',
  },
  {
    id: 2,
    value: 'Platinum Add Ons',
  },
];
export const PACKAGE_ADDONS = 1048;
export const PACKAGE_PLATINUM = 1001;
export const PACKAGE_PLATINUM_ADDONS = 1047;
