import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from './type/type';
import { RootState } from '@redux/rootReducer';
import { PaginationInterface } from '@utils/interface/pagination.interface';
import { BulkMappingData, FilterBulkMappingInteface } from '@pages/settings/company-mapping/bulk-mapping/interface/bulkMapping.interface';
import { SettingCompanyBulkResponse } from './type/settings/mapping/company';
import { paginationCalculation, stringMatch } from '@utils/helper';
import { bulkCompanyMapping } from './helper/mapping/company.api';
import { BulkCompanyMapper } from './helper/mapping/company.mapper';

export const uploadBulk = createAsyncThunk<SettingCompanyBulkResponse, any>(
  'setting/company/bulk',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await bulkCompanyMapping(payload);
      if (response?.status_code !== 200 || response?.status_code !== 201) {
        rejectWithValue(response);
      }
      const { current, showInValue: pageSize, totalData: total } = paginationCalculation({
        current: 1,
        totalData: response?.data ? response?.data.length : 0,
        showInValue: 15,
      });

      const result = {
        data: response?.data ? BulkCompanyMapper(response?.data) : null,
        pagination: {
          current,
          pageSize,
          total,
        },
        message: response?.message,
        status_code: response?.status_code,
      } as SettingCompanyBulkResponse;

      return result || null;
    } catch (e: any) {
      rejectWithValue(e);
      return e;
    }
  },
);

export const settingCompanyMappingSlice = createSlice({
  name: 'setting/company',
  initialState: {
    status: undefined as RequestState,
    bulk: {
      data: undefined as BulkMappingData[],
      table: undefined as BulkMappingData[],
      loading: false as boolean,
      pagination: undefined as PaginationInterface,
      filter: undefined as FilterBulkMappingInteface,
      error: false as boolean,
      errorMessage: undefined as string,
      success: false as boolean,
      successMessage: undefined as string,
    },
  },
  reducers: {
    afterError: (state, action) => {
      const {
        payload: { type, value },
      } = action;
      switch (type) {
        case 'bulk':
          switch (value) {
            case 'success':
              state.bulk.success = false;
              state.bulk.successMessage = undefined;
              break;
            case 'error':
              state.bulk.error = false;
              state.bulk.errorMessage = undefined;
              break;
          }
          break;
        default:
          break;
      }
    },
    updateFilter: (state, action) => {
      const { payload } = action;
      if (state.bulk.data) {
        let newTable = state.bulk.data;
        if (payload?.status) {
          if (payload?.keyword) {
            newTable = newTable.filter(item => item.status_code === payload.status && stringMatch(payload.keyword.toString(), item.search));
          } else {
            newTable = newTable.filter(item => item.status_code === payload.status);
          }
        } else if (payload?.keyword) {
          if (payload?.status) {
            newTable = newTable.filter(item => item.status_code === payload.status && stringMatch(payload.keyword.toString(), item.search));
          } else {
            newTable = newTable.filter(item => stringMatch(payload.keyword.toString(), item.search));
          }
        }

        const { current, showInValue: pageSize, totalData: total } = paginationCalculation({
          current: 1,
          totalData: newTable.length || 0,
          showInValue: state.bulk.pagination.pageSize || 15,
        });
        state.bulk.table = newTable;
        state.bulk.pagination = { current, pageSize, total };
        state.bulk.filter = payload;
      }
    },
    updatePagination: (state, action) => {
      const { payload } = action;
      state.bulk.pagination = payload;
    },
    resetBulk: state => {
      state.bulk = {
        data: undefined,
        table: undefined,
        loading: false,
        pagination: undefined,
        filter: undefined,
        error: false,
        errorMessage: undefined,
        success: false,
        successMessage: undefined,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadBulk.pending, state => {
      state.status = 'pending';
      state.bulk.loading = true;
    });
    builder.addCase(uploadBulk.fulfilled, (state, action) => {
      const { data, pagination, status_code, message } = action.payload;
      state.status = 'fulfilled';
      state.bulk.data = data;
      state.bulk.table = data;
      state.bulk.pagination = pagination;
      state.bulk.loading = false;
      if (status_code === 200 || status_code === 201) {
        state.bulk.error = false;
        state.bulk.errorMessage = undefined;
        state.bulk.success = true;
        state.bulk.successMessage = message;
      } else {
        state.bulk.error = status_code !== 200;
        state.bulk.errorMessage = message || undefined;
      }
    });
    builder.addCase(uploadBulk.rejected, state => {
      state.status = 'rejected';
      state.bulk.data = null;
      state.bulk.loading = false;
    });
  },
});

export const selectMappingCompany = (state: RootState) => state.mapping.company.bulk.data;
export const selectMappingCompanyTable = (state: RootState) => state.mapping.company.bulk.table;
export const selectCompany = (state: RootState) => state.mapping.company.bulk.detail;
export const selectMappingCompanyStatus = (state: RootState) => state.mapping.company.bulk.status;
export const selectMappingCompanyLoading = (state: RootState) => state.mapping.company.bulk.loading;
export const selectMappingCompanyError = (state: RootState) => state.mapping.company.bulk.error;
export const selectMappingCompanyErrorMessage = (state: RootState) => state.mapping.company.bulk.errorMessage;
export const selectMappingCompanySuccess = (state: RootState) => state.mapping.company.bulk.success;
export const selectMappingCompanySuccessMessage = (state: RootState) => state.mapping.company.bulk.successMessage;
export const selectCompanyPagination = (state: RootState) => state.mapping.company.bulk.pagination;
export const selectCompanyBulkFilter = (state: RootState) => state.mapping.company.bulk.filter;
export const { actions, reducer } = settingCompanyMappingSlice;
export const { afterError, updateFilter, updatePagination, resetBulk } = actions;
export default reducer;
