import {
  GET_PROVINCE,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAILURE,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAILURE,
  GET_DISTRICT,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAILURE,
  LocationDispatchTypes,
} from './location-types';

interface DefaultStateI {
  loadingProvince: boolean;
  errorProvince: boolean;
  successProvince: boolean;
  province: any;
  loadingCity: boolean;
  errorCity: boolean;
  successCity: boolean;
  city: any;
  loadingDistrict: boolean;
  errorDistrict: boolean;
  successDistrict: boolean;
  district: any;
}

const defaultState: DefaultStateI = {
  loadingProvince: false,
  errorProvince: false,
  successProvince: false,
  province: {},
  loadingCity: false,
  errorCity: false,
  successCity: false,
  city: {},
  loadingDistrict: false,
  errorDistrict: false,
  successDistrict: false,
  district: {},
};

const locationReducer = (state: DefaultStateI = defaultState, action: LocationDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_PROVINCE:
      return {
        ...state,
        loadingProvince: true,
        errorProvince: false,
        successProvince: false,
      };
    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        loadingProvince: false,
        errorProvince: false,
        successProvince: true,
        province: action.payload,
      };
    case GET_PROVINCE_FAILURE:
      return {
        ...state,
        loadingProvince: false,
        errorProvince: true,
        successProvince: false,
      };
    case GET_CITY:
      return {
        ...state,
        loadingCity: true,
        errorCity: false,
        successCity: false,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        loadingCity: false,
        errorCity: false,
        successCity: true,
        city: action.payload,
      };
    case GET_CITY_FAILURE:
      return {
        ...state,
        loadingCity: false,
        errorCity: true,
        successCity: false,
      };
    case GET_DISTRICT:
      return {
        ...state,
        loadingDistrict: true,
        errorDistrict: false,
        successDistrict: false,
      };
    case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        loadingDistrict: false,
        errorDistrict: false,
        successDistrict: true,
        district: action.payload,
      };
    case GET_DISTRICT_FAILURE:
      return {
        ...state,
        loadingDistrict: false,
        errorDistrict: true,
        successDistrict: false,
      };
    default:
      return state;
  }
};

export default locationReducer;
