import {
  GET_SEARCH_SUGGESTION,
  GET_SEARCH_SUGGESTION_SUCCESS,
  GET_SEARCH_SUGGESTION_FAILURE,
  SearchSuggestionDispatchTypes,
  searchSuggestionType,
} from './types';

interface DefaultStateI {
  loading: boolean;
  searchSuggestions?: searchSuggestionType;
}

const defaultState: DefaultStateI = {
  loading: false,
};

const reducer = (state: DefaultStateI = defaultState, action: SearchSuggestionDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_SEARCH_SUGGESTION:
      return {
        loading: false,
      };
    case GET_SEARCH_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: true,
        searchSuggestions: action.payload,
      };
    case GET_SEARCH_SUGGESTION_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
