import { Col, Drawer, Row, Spin } from 'antd';
import '@assets/css/notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NotificationContent } from '.';
import { actionMarkAsRead } from '@redux/settings/setting-action';
import toast from '@components/toast/toast';
import { groupBy, values as toObject } from 'lodash';
import moment from 'moment';

const NotificationDrawer: React.FC<LeadsFilterParams> = ({ visible, onClose, reloadNotifications }: LeadsFilterParams) => {
  const dispatch = useDispatch();
  const notificationData = useSelector((state: any) => state.settings);
  const { notification, notificationTotal, loadingGetNotification } = notificationData;

  const [dataNotification, setDataNotifications] = useState([]);

  const onCloseDrawer = () => {
    if (onClose != null) onClose();
  };

  useEffect(() => {
    if (notification?.length > 0) {
      groupingNotification();
    }
  }, [notification]);

  const groupingNotification = () => {
    const notificationByDate = notification?.map((data, id) => {
      data.date = moment(data.created_at).format('DD-MM-YYYY');
      return data;
    });
    const groupingObject = groupBy(notificationByDate, 'date');
    const grupingNotification = toObject(groupingObject);
    grupingNotification?.map((data, i) => {
      grupingNotification[i]['date'] = Object.entries(groupingObject[Object.keys(groupingObject)[i]])[0][1].created_at;
    });
    setDataNotifications(grupingNotification);
  };

  const markAllAsRead = async () => {
    if (notificationTotal.total_notification_unread > 0) {
      await dispatch(actionMarkAsRead('all'));
      reloadNotifications();
    }
    toast({
      type: 'success',
      message: 'Successful mark all notification as read',
    });
  };

  return (
    <Drawer
      title='Notification'
      width={576}
      onClose={onCloseDrawer}
      visible={visible}
      className={dataNotification?.length > 0 ? 'notification-drawer' : 'notification-drawer notification-drawer-no-data '}
    >
      <Spin spinning={loadingGetNotification}>
        {(dataNotification?.length > 0 &&
          dataNotification.map((data, i) => {
            return (
              <Row justify={(i == 0 && 'space-between') || 'start'}>
                <Col
                  lg={i == 0 ? 12 : 24}
                  style={
                    i == 0
                      ? {
                          fontWeight: 'bold',
                          marginBottom: '16px',
                        }
                      : {
                          fontWeight: 'bold',
                          marginBottom: '16px',
                          marginTop: '24px',
                        }
                  }
                >
                  {data &&
                    data.date &&
                    moment(data && data.date)
                      .tz('Asia/Jakarta')
                      .calendar({
                        sameDay: '[Today]',
                        lastDay: 'dddd, DD MMMM YYYY',
                        lastWeek: 'dddd, DD MMMM YYYY',
                        sameElse: 'DD MMMM YYYY',
                      })}
                </Col>
                {i == 0 && (
                  <Col lg={12} className='notification-mark-as-done' onClick={markAllAsRead}>
                    Mark all as read
                  </Col>
                )}
                <Col lg={24}>
                  {data?.map((item, index) =>
                    NotificationContent({ data: item, i: index, reloadNotifications, onCloseDrawer, markAllAsRead }),
                  )}
                </Col>
              </Row>
            );
          })) || <div>You don’t have any notifications right now</div>}
      </Spin>
    </Drawer>
  );
};

export interface LeadsFilterParams {
  // {visible, setVisible, onClose, onShow, updateAction, selector: (state:any)=>any}
  visible: boolean;
  onClose?: Function;
  reloadNotifications: any;
}

export default NotificationDrawer;
