import * as dotenv from 'dotenv';
import { EndpointInterface } from './interface/env.interface';

dotenv.config();

export const endpoint: EndpointInterface = {
  baseUrl: process.env.REACT_APP_CRM_BASE_URL,
  version: process.env.REACT_APP_CRM_VERSION,
};

export const VAT = process.env.REACT_APP_VAT_PERCENTAGE;
