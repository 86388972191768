import {
  GET_AGENTS,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAILURE,
  GET_AGENT_INFORMATION,
  GET_AGENT_INFORMATION_SUCCESS,
  GET_AGENT_INFORMATION_FAILURE,
  CREATE_CONVERT_CUSTOMER_TO_LEAD,
  CREATE_CONVERT_CUSTOMER_TO_LEAD_SUCCESS,
  CREATE_CONVERT_CUSTOMER_TO_LEAD_FAILURE,
  GET_CUSTOMER_ACTIVITY_HISTORY,
  GET_CUSTOMER_ACTIVITY_HISTORY_SUCCESS,
  GET_CUSTOMER_ACTIVITY_HISTORY_FAILURE,
  GET_CUSTOMER_TRANSACTION_HISTORY,
  GET_CUSTOMER_TRANSACTION_HISTORY_SUCCESS,
  GET_CUSTOMER_TRANSACTION_HISTORY_FAILURE,
  UPDATE_FILTER_CUSTOMERS,
  AgentDispatchTypes,
  agentType,
  BulkConvertLeadsInetrface,
  BULK_CONVERT_LEADS,
  BULK_CONVERT_LEADS_SUCCESS,
  BULK_CONVERT_LEADS_FAILURE,
  BULK_CONVERT_LEADS_RESET,
} from './agent-types';

interface DefaultStateI {
  loading?: boolean;
  agents?: agentType;
  loadingGetAgent?: boolean;
  agent?: any;
  errorGetAgentInformation?: boolean;
  errorPayloadAgentInformation: any;
  loadingConvertCustomerToLead?: boolean;
  successConvertCustomerToLead?: boolean;
  errorConvertCustomerToLead?: boolean;
  messageConvertCustomerToLead?: string;

  loadingGetHistoryActivityCustomer: boolean;
  successGetHistoryActivityCustomer: boolean;
  errorGetHistoryActivityCustomer: boolean;
  historyActivityCustomer?: any;

  loadingGetHistoryTransactionCustomer: boolean;
  successGetHistoryTransactionCustomer: boolean;
  errorGetHistoryTransactionCustomer: boolean;
  historyTransactionCustomer?: any;

  filterQuery: any;

  loadingBulkConvertLeads: boolean;
  successBulkConvertLeads: boolean;
  errorBulkConvertLeads: boolean;
  messageBulkConvertLeads: string;
  bulkConvertLeads?: BulkConvertLeadsInetrface;
}

const defaultState: DefaultStateI = {
  loading: false,
  loadingGetAgent: false,
  agent: {},
  loadingConvertCustomerToLead: false,
  successConvertCustomerToLead: false,
  errorConvertCustomerToLead: false,
  messageConvertCustomerToLead: '',

  loadingGetHistoryActivityCustomer: false,
  successGetHistoryActivityCustomer: false,
  errorGetHistoryActivityCustomer: false,
  historyActivityCustomer: [],

  loadingGetHistoryTransactionCustomer: false,
  successGetHistoryTransactionCustomer: false,
  errorGetHistoryTransactionCustomer: false,
  historyTransactionCustomer: [],

  errorGetAgentInformation: false,
  errorPayloadAgentInformation: {},

  filterQuery: {},

  loadingBulkConvertLeads: false,
  successBulkConvertLeads: false,
  errorBulkConvertLeads: false,
  messageBulkConvertLeads: '',
  bulkConvertLeads: {
    data: [],
    total_success: 0,
    total_failed: 0,
    total: 0,
  },
};

const agentsReducer = (state: DefaultStateI = defaultState, action: AgentDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload,
      };
    case GET_AGENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_AGENT_INFORMATION:
      return {
        ...state,
        loadingGetAgent: true,
        errorGetAgentInformation: false,
      };
    case GET_AGENT_INFORMATION_SUCCESS:
      return {
        ...state,
        loadingGetAgent: false,
        agent: action.payload,
        errorGetAgentInformation: false,
      };
    case GET_AGENT_INFORMATION_FAILURE:
      return {
        ...state,
        loadingGetAgent: false,
        errorPayloadAgentInformation: action.payload,
        errorGetAgentInformation: true,
      };
    case CREATE_CONVERT_CUSTOMER_TO_LEAD:
      return {
        ...state,
        loadingConvertCustomerToLead: true,
        successConvertCustomerToLead: false,
        errorConvertCustomerToLead: false,
        messageConvertCustomerToLead: '',
      };
    case CREATE_CONVERT_CUSTOMER_TO_LEAD_SUCCESS:
      return {
        ...state,
        loadingConvertCustomerToLead: false,
        successConvertCustomerToLead: true,
        messageConvertCustomerToLead: action.message,
      };
    case CREATE_CONVERT_CUSTOMER_TO_LEAD_FAILURE:
      return {
        ...state,
        loadingConvertCustomerToLead: false,
        successConvertCustomerToLead: false,
        errorConvertCustomerToLead: true,
      };
    case GET_CUSTOMER_ACTIVITY_HISTORY:
      return {
        ...state,
        loadingGetHistoryActivityCustomer: true,
        successGetHistoryActivityCustomer: false,
        errorGetHistoryActivityCustomer: false,
      };
    case GET_CUSTOMER_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistoryActivityCustomer: false,
        successGetHistoryActivityCustomer: true,
        errorGetHistoryActivityCustomer: false,
        historyActivityCustomer: action.payload,
      };
    case GET_CUSTOMER_ACTIVITY_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistoryActivityCustomer: false,
        successGetHistoryActivityCustomer: false,
        errorGetHistoryActivityCustomer: true,
      };
    case GET_CUSTOMER_TRANSACTION_HISTORY:
      return {
        ...state,
        loadingGetHistoryTransactionCustomer: true,
        successGetHistoryTransactionCustomer: false,
        errorGetHistoryTransactionCustomer: false,
      };
    case GET_CUSTOMER_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistoryTransactionCustomer: false,
        successGetHistoryTransactionCustomer: true,
        errorGetHistoryTransactionCustomer: false,
        historyTransactionCustomer: action.payload,
      };
    case GET_CUSTOMER_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistoryTransactionCustomer: false,
        successGetHistoryTransactionCustomer: false,
        errorGetHistoryTransactionCustomer: true,
      };
    case UPDATE_FILTER_CUSTOMERS:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case BULK_CONVERT_LEADS:
      return {
        ...state,
        loadingBulkConvertLeads: true,
        successBulkConvertLeads: false,
        errorBulkConvertLeads: false,
        messageBulkConvertLeads: '',
      };
    case BULK_CONVERT_LEADS_SUCCESS:
      const LeadsBulk = {
        data: action.payload.bulk_lead_assigns,
        total_success: action.payload.total_success,
        total_failed: action.payload.total_failed,
        total: action.payload.total,
      };

      return {
        ...state,
        loadingBulkConvertLeads: false,
        successBulkConvertLeads: true,
        errorBulkConvertLeads: false,
        bulkConvertLeads: LeadsBulk,
      };
    case BULK_CONVERT_LEADS_FAILURE:
      return {
        ...state,
        loadingBulkConvertLeads: false,
        errorBulkConvertLeads: true,
        successBulkConvertLeads: false,
        messageBulkConvertLeads: action.message,
      };
    case BULK_CONVERT_LEADS_RESET:
      return {
        ...state,
        loadingBulkConvertLeads: false,
        errorBulkConvertLeads: false,
        successBulkConvertLeads: false,
        messageBulkConvertLeads: '',
        bulkConvertLeads: {
          data: [],
          total_success: 0,
          total_failed: 0,
          total: 0,
        },
      };
    default:
      return state;
  }
};

export default agentsReducer;
