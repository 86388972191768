export const GET_APPROVAL = 'GET_APPROVAL';
export const GET_APPROVAL_SUCCESS = 'GET_APPROVAL_SUCCESS';
export const GET_APPROVAL_FAILURE = 'GET_APPROVAL_FAILURE';
//Approval Discount
export const UPDATE_APPROVAL_FILTER = 'UPDATE_APPROVAL_FILTER';
export const UPDATE_APPROVAL = 'UPDATE_APPROVAL';
export const UPDATE_APPROVAL_SUCCESS = 'UPDATE_APPROVAL_SUCCESS';
export const UPDATE_APPROVAL_FAILURE = 'UPDATE_APPROVAL_FAILURE';

//Approval Payment
export const CREATE_APPROVAL = 'CREATE_APPROVAL';
export const CREATE_APPROVAL_SUCCESS = 'CREATE_APPROVAL_SUCCESS';
export const CREATE_APPROVAL_FAILURE = 'CREATE_APPROVAL_FAILURE';

//Approval Payment
export const REPORT_APPROVAL = 'REPORT_APPROVAL';
export const REPORT_APPROVAL_SUCCESS = 'REPORT_APPROVAL_SUCCESS';
export const REPORT_APPROVAL_FAILURE = 'REPORT_APPROVAL_FAILURE';

export type myApprovalType = {
  data: any;
};

export interface GetApproval {
  type: typeof GET_APPROVAL;
}
export interface GetApprovalSuccess {
  type: typeof GET_APPROVAL_SUCCESS;
  payload: myApprovalType;
}

export interface GetApprovalFailure {
  type: typeof GET_APPROVAL_FAILURE;
}
export interface UpdateFilterApproval {
  type: typeof UPDATE_APPROVAL_FILTER;
  payload: any;
}
export interface updateApproval {
  type: typeof UPDATE_APPROVAL;
}

export interface updateApprovalSuccess {
  type: typeof UPDATE_APPROVAL_SUCCESS;
  payload: any;
}

export interface updateApprovalFailure {
  type: typeof UPDATE_APPROVAL_FAILURE;
}

export interface CreateApprovalPayment {
  type: typeof CREATE_APPROVAL;
}

export interface CreateApprovalPaymentSuccess {
  type: typeof CREATE_APPROVAL_SUCCESS;
  payload: any;
}

export interface CreateApprovalPaymentFailure {
  type: typeof CREATE_APPROVAL_FAILURE;
  message: any;
}

export interface ReportApprovalPayment {
  type: typeof REPORT_APPROVAL;
}

export interface ReportApprovalPaymentSuccess {
  type: typeof REPORT_APPROVAL_SUCCESS;
}

export interface ReportApprovalPaymentFailure {
  type: typeof REPORT_APPROVAL_FAILURE;
  message: any;
}

export type MyApprovalDispatchTypes =
  | GetApproval
  | GetApprovalSuccess
  | GetApprovalFailure
  | updateApproval
  | updateApprovalSuccess
  | updateApprovalFailure
  | UpdateFilterApproval
  | CreateApprovalPayment
  | CreateApprovalPaymentSuccess
  | CreateApprovalPaymentFailure
  | ReportApprovalPayment
  | ReportApprovalPaymentSuccess
  | ReportApprovalPaymentFailure;
