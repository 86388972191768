import { Dispatch } from 'redux';
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  UserDispatchTypes,
} from './auth-types';
import { loginService } from './auth-service';
import { setUserData, clearAll } from '@utils/storage';

export const userLogin = (user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: USER_LOGIN,
      error: false,
    });

    const res: any = await loginService(user);

    const user_info = {
      isLoggedIn: true,
      email: res?.data?.users?.email,
      fullname: res?.data?.users?.fullname,
      roles: {
        guard_name: res?.data?.users?.roles.guard_name,
        level: res?.data?.users?.roles.level,
      },
    };

    const users = {
      user_info,
      access_token: res?.data?.access_token,
      refresh_token: res?.data?.refresh_token,
    };

    setUserData(users);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        token: res.data.access_token,
        refresh_token: res.data.refresh_token,
        user_info: res.data.users,
      },
      messages: null,
    });
  } catch (e: any) {
    if (e.response) {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: e.response.data,
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: { message: 'Network Failure. Please contact administrator.' },
      });
    }
  }
};

export const userLogout = () => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: USER_LOGOUT,
    });

    clearAll();
    window.location.href = '/auth/login';
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
  } catch (e: any) {
    dispatch({
      type: USER_LOGOUT_FAILURE,
    });
  }
};
