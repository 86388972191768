export const APPROVAL_DISCOUNT = 'APPROVAL_DISCOUNT';
export const APPROVAL_DISCOUNT_SUCCESS = 'APPROVAL_DISCOUNT_SUCCESS';
export const APPROVAL_DISCOUNT_FAILURE = 'APPROVAL_DISCOUNT_FAILURE';

export const DECLINE_NOTES = 'DECLINE_NOTES';
export const DECLINE_NOTES_SUCCESS = 'DECLINE_NOTES_SUCCESS';
export const DECLINE_NOTES_FAILURE = 'DECLINE_NOTES_FAILURE';

export type discountType = {
  data: any;
};

export type declineType = {
  data: any;
};

export interface GetApproval {
  type: typeof APPROVAL_DISCOUNT;
}

export interface GetApprovalSuccess {
  type: typeof APPROVAL_DISCOUNT_SUCCESS;
  payload: discountType;
}

export interface GetApprovalFailure {
  type: typeof APPROVAL_DISCOUNT_FAILURE;
}

export interface GetDeclineNotes {
  type: typeof DECLINE_NOTES;
}

export interface GetDeclineNotesSuccess {
  type: typeof DECLINE_NOTES_SUCCESS;
  payload: declineType;
}

export interface GetDeclineNotesFailure {
  type: typeof DECLINE_NOTES_FAILURE;
}

export type ConfirmDispatchTypes =
  | GetApproval
  | GetApprovalSuccess
  | GetApprovalFailure
  | GetDeclineNotes
  | GetDeclineNotesSuccess
  | GetDeclineNotesFailure;
