const version_storage = '1';
export let ACCESS_TOKEN_NAME = 'crm_token_v' + version_storage;
export let REFRESH_TOKEN_NAME = 'crm_refresh_v' + version_storage;
export let USER_INFO_NAME = 'crm_user_v' + version_storage;

export const setUserData = data => {
  localStorage.setItem(ACCESS_TOKEN_NAME, data.access_token);
  localStorage.setItem(REFRESH_TOKEN_NAME, data.refresh_token);
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(data.user_info));
};

export const removeUserData = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  localStorage.removeItem(USER_INFO_NAME);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_NAME) || null;
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_NAME) || null;
};

export const getUserInfo = () => {
  let user_info = localStorage.getItem(USER_INFO_NAME) || null;
  if (user_info) {
    return JSON.parse(user_info);
  }
  return null;
};

export const isLoggedIn = () => {
  let user_info = localStorage.getItem(USER_INFO_NAME) || null;
  let access_token = localStorage.getItem(ACCESS_TOKEN_NAME) || null;
  let refresh_token = localStorage.getItem(REFRESH_TOKEN_NAME) || null;
  return user_info && access_token && refresh_token ? true : false;
};

export const clearAll = () => {
  localStorage.clear();
};
