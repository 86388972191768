import { notification as toast } from 'antd';

import '@assets/css/toasts.scss';

const Toast = props => {
  const { message, type } = props;
  return toast.open({
    ...props,
    className: type ? `${type} ui-toast` : 'ui-toast',
    message: message,
  });
};

export default Toast;
