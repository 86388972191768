import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import SideNavigation from '@components/sidebar/sidebar';
import HeaderApp from './header';
import LoadingBar from '@components/loading/loading';
import '@assets/css/layout.scss';

const { Sider, Content } = Layout;

const LayoutApp = (props: any): any => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
  }, []);

  const handleToggle = (event: any) => {
    event.preventDefault();
    collapse ? setCollapse(false) : setCollapse(true);
  };

  const RenderContent = () => {
    return (
      <Content className='ant-layout-content-main' style={{ overflowY: 'auto', maxHeight: '10vh' }}>
        {props.children}
      </Content>
    );
  };
  return (
    <Layout className={`crm-layout ${collapse ? 'crm-layout-collapse' : 'crm-layout-full'}`}>
      <LoadingBar loading={props.loading} />
      <Sider trigger={null} collapsible collapsed={collapse} width={235}>
        <SideNavigation syncNotifications={props.syncNotifications} />
      </Sider>
      <Layout>
        <HeaderApp handleToggle={handleToggle} isCollapse={collapse} />
        {!props.loading && RenderContent()}
      </Layout>
    </Layout>
  );
};

export default LayoutApp;
