import React from 'react';
import { Switch } from 'react-router-dom';
import RoutePrivate from './route-private';

function RouteChildren({ routes, fallback, permissions }) {
  return (
    <React.Suspense fallback={fallback}>
      <Switch>
        {routes.map((route, idx) => {
          return route.component ? <RoutePrivate key={idx} {...route} permissions={permissions} /> : null;
        })}
      </Switch>
    </React.Suspense>
  );
}

export default RouteChildren;
