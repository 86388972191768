import ApiClient from '@utils/api-client';
import { ApiEndpoint } from '@utils/constant/api-enpoint.constant';

const { settings } = ApiEndpoint;
const {
  base,
  company: { companyRegionArea },
} = settings;

const bulkCompanyMapping = (payload: any) =>
  ApiClient.withAuth().then(api =>
    api.post(`${base}${companyRegionArea.base}${companyRegionArea.bulk}`, payload, {}).then(result => result?.data),
  );

export { bulkCompanyMapping };
