import {
  CREATE_LEAD,
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_SUCCESS,
  CREATE_SENT_AE,
  CREATE_SENT_AE_FAILURE,
  CREATE_SENT_AE_SUCCESS,
  DELETE_LEAD_PRODUCT,
  DELETE_LEAD_PRODUCT_FAILURE,
  DELETE_LEAD_PRODUCT_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_SUCCESS,
  GET_LEAD,
  GET_LEAD_ACTIVITY_HISTORY,
  GET_LEAD_ACTIVITY_HISTORY_FAILURE,
  GET_LEAD_ACTIVITY_HISTORY_SUCCESS,
  GET_LEAD_FAILURE,
  GET_LEAD_SUCCESS,
  GET_LEAD_TRANSACTION_HISTORY,
  GET_LEAD_TRANSACTION_HISTORY_FAILURE,
  GET_LEAD_TRANSACTION_HISTORY_SUCCESS,
  GET_STATUS_CALL_SUCCESS,
  LeadDispatchTypes,
  UPDATE_ACTIVITY_HISTORY,
  UPDATE_ACTIVITY_HISTORY_FAILURE,
  UPDATE_ACTIVITY_HISTORY_SUCCESS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAILURE,
  CREATE_ASSIGN_MAPPING_LOCATION_AE,
  CREATE_ASSIGN_MAPPING_LOCATION_AE_SUCCESS,
  CREATE_ASSIGN_MAPPING_LOCATION_AE_FAILURE,
  UPDATE_LEAD_SUCCESS,
  GET_CALCULATION_PACKAGE,
  GET_CALCULATION_PACKAGE_SUCCESS,
  GET_CALCULATION_PACKAGE_FAILURE,
  UPDATE_ACTIVITY_DONE,
  UPDATE_ACTIVITY_DONE_SUCCESS,
  UPDATE_ACTIVITY_DONE_FAILURE,
  SENT_INVOICE,
  SENT_INVOICE_SUCCESS,
  SENT_INVOICE_FAILURE,
  GET_CALCULATION_TRANSACTION,
  GET_CALCULATION_TRANSACTION_SUCCESS,
  GET_CALCULATION_TRANSACTION_FAILURE,
  UPDATE_LEAD_VERIFY,
  UPDATE_LEAD_VERIFY_SUCCESS,
  UPDATE_LEAD_VERIFY_FAILURE,
} from './lead-types';

interface DefaultStateI {
  loadingGetLead: boolean;
  successGetLead: boolean;
  errorGetLead: boolean;
  lead?: any;

  loadingGetCampaign: boolean;
  successGetCampaign: boolean;
  errorGetCampaign: boolean;
  campaign?: any;

  loadingGetHistoryTransactionLead: boolean;
  successGetHistoryTransactionLead: boolean;
  errorGetHistoryTransactionLead: boolean;
  historyTransaction?: any;

  loadingUpdateLead: boolean;
  successUpdateLead: boolean;
  errorUpdateLead: boolean;

  loadingGetHistoryActivityLead: boolean;
  successGetHistoryActivityLead: boolean;
  errorGetHistoryActivityLead: boolean;
  historyActivity?: any;

  loadingUpdateHistoryActivityLead: boolean;
  successUpdateHistoryActivityLead: boolean;
  errorUpdateHistoryActivityLead: boolean;

  loadingUpdateDoneActivityLead: boolean;
  successUpdateDoneActivityLead: boolean;
  errorUpdateDoneActivityLead: boolean;

  loadingCreateSentAE: boolean;
  successCreateSentAE: boolean;
  errorCreateSentAE: boolean;

  loadingCreateLead: boolean;
  successCreateLead: boolean;
  errorCreateLead: boolean;
  errorMessage: any;

  loadingDeleteLeadProduct: boolean;
  successDeleteLeadProduct: boolean;
  errorDeleteLeadProduct: boolean;
  messageDeleteLeadProduct: any;
  errorMessageDeleteLeadProduct: any;

  listStatus: any;

  loadingCreateAssignMappingLocationAE: boolean;
  successCreateAssignMappingLocationAE: boolean;
  errorCreateAssignMappingLocationAE: boolean;
  assignMappingLocationAE: any;
  assignMappingLocationAEMessage: string;

  loadingGetCalculation: boolean;
  successGetCalculation: boolean;
  errorGetCalculation: boolean;
  leadCalculateResult: any;

  loadingSentInvoice: boolean;
  successSentInvoice: boolean;
  errorSentInvoice: boolean;
  messageSentInvoice: string;
}

const defaultState: DefaultStateI = {
  loadingGetLead: false,
  successGetLead: false,
  errorGetLead: false,
  lead: {},

  loadingGetCampaign: false,
  successGetCampaign: false,
  errorGetCampaign: false,
  campaign: {},

  loadingGetHistoryTransactionLead: false,
  successGetHistoryTransactionLead: false,
  errorGetHistoryTransactionLead: false,
  historyTransaction: {},

  loadingGetHistoryActivityLead: false,
  successGetHistoryActivityLead: false,
  errorGetHistoryActivityLead: false,
  historyActivity: {},

  loadingUpdateHistoryActivityLead: false,
  successUpdateHistoryActivityLead: false,
  errorUpdateHistoryActivityLead: false,

  loadingUpdateDoneActivityLead: false,
  successUpdateDoneActivityLead: false,
  errorUpdateDoneActivityLead: false,

  loadingUpdateLead: false,
  successUpdateLead: false,
  errorUpdateLead: false,

  loadingCreateLead: false,
  successCreateLead: false,
  errorCreateLead: false,

  loadingCreateSentAE: false,
  successCreateSentAE: false,
  errorCreateSentAE: false,
  errorMessage: {},

  loadingDeleteLeadProduct: false,
  successDeleteLeadProduct: false,
  errorDeleteLeadProduct: false,
  messageDeleteLeadProduct: {},
  errorMessageDeleteLeadProduct: {},

  listStatus: [],

  loadingCreateAssignMappingLocationAE: false,
  successCreateAssignMappingLocationAE: false,
  errorCreateAssignMappingLocationAE: false,
  assignMappingLocationAE: {},
  assignMappingLocationAEMessage: '',

  loadingGetCalculation: false,
  successGetCalculation: false,
  errorGetCalculation: false,
  leadCalculateResult: {},

  loadingSentInvoice: false,
  successSentInvoice: false,
  errorSentInvoice: false,
  messageSentInvoice: '',
};

const leadReducer = (state: DefaultStateI = defaultState, action: LeadDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_LEAD:
      return {
        ...state,
        loadingGetLead: true,
        successGetLead: false,
        errorGetLead: false,
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        loadingGetLead: false,
        successGetLead: true,
        errorGetLead: false,
        lead: action.payload,
      };
    case GET_LEAD_FAILURE:
      return {
        ...state,
        loadingGetLead: false,
        successGetLead: false,
        errorGetLead: true,
        errorMessage: action.message,
      };

    case GET_CAMPAIGN:
      return {
        ...state,
        loadingGetCampaign: true,
        successGetCampaign: false,
        errorGetCampaign: false,
      };

    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loadingGetCampaign: true,
        successGetCampaign: false,
        errorGetCampaign: false,
        campaign: action.payload,
      };
    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        loadingGetCampaign: true,
        successGetCampaign: false,
        errorGetCampaign: false,
      };
    case GET_STATUS_CALL_SUCCESS:
      return {
        ...state,
        listStatus: action.payload,
      };
    case GET_LEAD_TRANSACTION_HISTORY:
      return {
        ...state,
        loadingGetHistoryTransactionLead: true,
        successGetHistoryTransactionLead: false,
        errorGetHistoryTransactionLead: false,
      };
    case GET_LEAD_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistoryTransactionLead: false,
        successGetHistoryTransactionLead: true,
        errorGetHistoryTransactionLead: false,
        historyTransaction: action.payload,
      };
    case GET_LEAD_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistoryTransactionLead: false,
        successGetHistoryTransactionLead: false,
        errorGetHistoryTransactionLead: true,
      };
    case GET_LEAD_ACTIVITY_HISTORY:
      return {
        ...state,
        loadingGetHistoryActivityLead: true,
        successGetHistoryActivityLead: false,
        errorGetHistoryActivityLead: false,
      };
    case GET_LEAD_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistoryActivityLead: false,
        successGetHistoryActivityLead: true,
        errorGetHistoryActivityLead: false,
        historyActivity: action.payload,
      };
    case GET_LEAD_ACTIVITY_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistoryActivityLead: false,
        successGetHistoryActivityLead: false,
        errorGetHistoryActivityLead: true,
      };
    case UPDATE_ACTIVITY_HISTORY:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: true,
        successUpdateHistoryActivityLead: false,
        errorUpdateHistoryActivityLead: false,
      };
    case UPDATE_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: false,
        successUpdateHistoryActivityLead: true,
        errorUpdateHistoryActivityLead: false,
        historyActivity: action.payload,
      };
    case UPDATE_ACTIVITY_HISTORY_FAILURE:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: false,
        successUpdateHistoryActivityLead: false,
        errorUpdateHistoryActivityLead: true,
      };
    case UPDATE_ACTIVITY_DONE:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: true,
        successUpdateHistoryActivityLead: false,
        errorUpdateHistoryActivityLead: false,
      };
    case UPDATE_ACTIVITY_DONE_SUCCESS:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: false,
        successUpdateHistoryActivityLead: true,
        errorUpdateHistoryActivityLead: false,
      };
    case UPDATE_ACTIVITY_DONE_FAILURE:
      return {
        ...state,
        loadingUpdateHistoryActivityLead: false,
        successUpdateHistoryActivityLead: false,
        errorUpdateHistoryActivityLead: true,
      };
    case UPDATE_LEAD:
      return {
        ...state,
        loadingUpdateLead: true,
        successUpdateLead: false,
        errorUpdateLead: false,
      };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: true,
        errorUpdateLead: false,
        lead: action.payload,
      };
    case UPDATE_LEAD_FAILURE:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: false,
        errorUpdateLead: true,
        errorMessage: action.message,
      };
    case CREATE_SENT_AE:
      return {
        ...state,
        loadingCreateSentAE: true,
        successCreateSentAE: false,
        errorCreateSentAE: false,
      };
    case CREATE_SENT_AE_SUCCESS:
      return {
        ...state,
        loadingCreateSentAE: false,
        successCreateSentAE: true,
        errorCreateSentAE: false,
        lead: action.payload,
      };
    case CREATE_SENT_AE_FAILURE:
      return {
        ...state,
        loadingCreateSentAE: false,
        successCreateSentAE: false,
        errorCreateSentAE: true,
      };

    case DELETE_LEAD_PRODUCT:
      return {
        ...state,
        loadingDeleteLeadProduct: true,
        successDeleteLeadProduct: false,
        errorDeleteLeadProduct: false,
      };
    case DELETE_LEAD_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingDeleteLeadProduct: false,
        successDeleteLeadProduct: true,
        errorDeleteLeadProduct: false,
        messageDeleteLeadProduct: action.message,
      };
    case DELETE_LEAD_PRODUCT_FAILURE:
      return {
        ...state,
        loadingDeleteLeadProduct: false,
        successDeleteLeadProduct: false,
        errorDeleteLeadProduct: true,
        errorMessageDeleteLeadProduct: action.message,
      };

    case CREATE_LEAD:
      return {
        ...state,
        loadingCreateLead: true,
        successCreateLead: false,
        errorCreateLead: false,
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        loadingCreateLead: false,
        successCreateLead: true,
        errorCreateLead: false,
        lead: action.payload,
      };
    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        loadingCreateLead: false,
        successCreateLead: false,
        errorCreateLead: true,
        errorMessage: action.message,
      };
    case CREATE_ASSIGN_MAPPING_LOCATION_AE:
      return {
        ...state,
        loadingCreateAssignMappingLocationAE: true,
        successCreateAssignMappingLocationAE: false,
        errorCreateAssignMappingLocationAE: false,
        assignMappingLocationAEMessage: '',
      };
    case CREATE_ASSIGN_MAPPING_LOCATION_AE_SUCCESS:
      return {
        ...state,
        loadingCreateAssignMappingLocationAE: false,
        successCreateAssignMappingLocationAE: true,
        errorCreateAssignMappingLocationAE: false,
        lead: action.payload,
        assignMappingLocationAEMessage: action.message,
      };
    case CREATE_ASSIGN_MAPPING_LOCATION_AE_FAILURE:
      return {
        ...state,
        loadingCreateAssignMappingLocationAE: false,
        errorCreateAssignMappingLocationAE: true,
        successCreateAssignMappingLocationAE: false,
        assignMappingLocationAEMessage: action.message,
      };

    case GET_CALCULATION_PACKAGE:
      return {
        ...state,
        loadingGetCalculation: true,
        successGetCalculation: false,
        errorGetCalculation: false,
      };
    case GET_CALCULATION_PACKAGE_SUCCESS:
      return {
        ...state,
        loadingGetCalculation: false,
        successGetCalculation: true,
        errorGetCalculation: false,
        leadCalculateResult: action.payload,
      };
    case GET_CALCULATION_PACKAGE_FAILURE:
      return {
        ...state,
        loadingGetCalculation: false,
        successGetCalculation: false,
        errorGetCalculation: true,
        leadCalculateResult: action.message,
      };
    case SENT_INVOICE:
      return {
        ...state,
        loadingSentInvoice: true,
        successSentInvoice: false,
        errorSentInvoice: false,
      };
    case SENT_INVOICE_SUCCESS:
      return {
        ...state,
        loadingSentInvoice: false,
        successSentInvoice: true,
        errorSentInvoice: false,
        messageSentInvoice: action.message,
      };
    case SENT_INVOICE_FAILURE:
      return {
        ...state,
        loadingSentInvoice: false,
        successSentInvoice: false,
        errorSentInvoice: true,
        messageSentInvoice: action.message,
      };
    case GET_CALCULATION_TRANSACTION:
      return {
        ...state,
        loadingGetCalculation: true,
        successGetCalculation: false,
        errorGetCalculation: false,
      };
    case GET_CALCULATION_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingGetCalculation: false,
        successGetCalculation: true,
        errorGetCalculation: false,
        leadCalculateResult: action.payload,
      };
    case GET_CALCULATION_TRANSACTION_FAILURE:
      return {
        ...state,
        loadingGetCalculation: false,
        successGetCalculation: false,
        errorGetCalculation: true,
        leadCalculateResult: action.message,
      };
    case UPDATE_LEAD_VERIFY:
      return {
        ...state,
        loadingUpdateLead: true,
        successUpdateLead: false,
        errorUpdateLead: false,
      };
    case UPDATE_LEAD_VERIFY_SUCCESS:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: true,
        errorUpdateLead: false,
        lead: action.payload,
      };
    case UPDATE_LEAD_VERIFY_FAILURE:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: false,
        errorUpdateLead: true,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default leadReducer;
