import { Dispatch } from 'redux';
import {
  GET_RESPONSIBLE_AGENT_AREA,
  GET_RESPONSIBLE_AGENT_AREA_SUCCESS,
  GET_RESPONSIBLE_AGENT_AREA_FAILURE,
  GET_RESPONSIBLE_AGENT,
  GET_RESPONSIBLE_AGENT_SUCCESS,
  GET_RESPONSIBLE_AGENT_FAILURE,
  SettingDispatchTypes,
  GET_IS_LOCATION_MAPPED,
  GET_IS_LOCATION_MAPPED_SUCCESS,
  GET_IS_LOCATION_MAPPED_FAILURE,
  GET_RESPONSIBLE_AGENT_REGION,
  GET_RESPONSIBLE_AGENT_REGION_SUCCESS,
  GET_RESPONSIBLE_AGENT_REGION_FAILURE,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_FAILURE,
  UPDATE_PRODUCT_SETTING_FILTER,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  UPDATE_STATUS_PRODUCT,
  UPDATE_STATUS_PRODUCT_SUCCESS,
  UPDATE_STATUS_PRODUCT_FAILURE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER,
  UPDATE_ACTIVE_PACKAGE,
  CREATE_COMPANY_MAPPING,
  CREATE_COMPANY_MAPPING_SUCCESS,
  CREATE_COMPANY_MAPPING_FAILURE,
  UPDATE_COMPANY_MAPPING,
  UPDATE_COMPANY_MAPPING_SUCCESS,
  UPDATE_COMPANY_MAPPING_FAILURE,
  GET_COMPANY_MAPPING,
  GET_COMPANY_MAPPING_SUCCESS,
  GET_COMPANY_MAPPING_FAILURE,
  DELETE_COMPANY_MAPPING,
  DELETE_COMPANY_MAPPING_SUCCESS,
  DELETE_COMPANY_MAPPING_FAILURE,
} from './setting-types';
import {
  getResponsibleAgentAreaService,
  getResponsibleAgent as getResponsibleAgentService,
  getIsLocationMappedService,
  getResponsibleAgentRegionService,
  getNotificationsService,
  actionMarkAsDoneService,
  actionMarkAllAsDoneService,
  getProductService,
  createProductService,
  getProductDetailService,
  updateProductService,
  deleteProductService,
  updateStatusProductService,
  getProductUpgradeService,
  getUpgradeDiscountTypeService,
  createUpgradeDiscountTypeService,
  updateStatusDiscountTypeService,
  deleteProductUpgradeDiscountTypeService,
  getProducUpgradeDiscountTypetDetailService,
  updateProductUpgradeDiscountTypeService,
  createProductUpgradeService,
  deleteProductUpgradeService,
  getProductUpgradeDetailService,
  updateProductUpgradeService,
  updateStatusProductUpgradeService,
  updateActivePackageService,
  createCompanyMappingService,
  getCompanyMappingService,
  updateCompanyMappingService,
  deleteCompanyMappingService,
} from './setting-service';
import { SETTING_TYPE_PRODUCT_ALACARTE, SETTING_TYPE_PRODUCT_MEMBERSHIP } from './constant/setting.constant';
import { SETTING_TYPE_PRODUCT_UPGRADE } from '@pages/settings/constant/setting.constant';

export const getResponsibleAgentArea = (statusType: number, locationId: number | null | undefined) => async (
  dispatch: Dispatch<SettingDispatchTypes>,
) => {
  try {
    dispatch({
      type: GET_RESPONSIBLE_AGENT_AREA,
    });

    const res = await getResponsibleAgentAreaService(statusType, locationId);

    dispatch({
      type: GET_RESPONSIBLE_AGENT_AREA_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_RESPONSIBLE_AGENT_AREA_FAILURE,
    });
  }
};

export const getResponsibleAgent = payload => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_RESPONSIBLE_AGENT,
    });

    const res = await getResponsibleAgentService(payload);

    dispatch({
      type: GET_RESPONSIBLE_AGENT_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_RESPONSIBLE_AGENT_FAILURE,
    });
  }
};

export const GetIsLocationIsMapped = (payload: { locationId: number | null | undefined; accessType: number }) => async (
  dispatch: Dispatch<SettingDispatchTypes>,
) => {
  try {
    dispatch({
      type: GET_IS_LOCATION_MAPPED,
    });

    const res = await getIsLocationMappedService(payload);

    dispatch({
      type: GET_IS_LOCATION_MAPPED_SUCCESS,
      payload: res.data,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    let message = e?.response?.data?.message || e.message;
    dispatch({
      type: GET_IS_LOCATION_MAPPED_FAILURE,
      message: message,
    });
  }
};

export const getResponsibleAgentRegion = (payload: { agentId: number }) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_RESPONSIBLE_AGENT_REGION,
    });

    const res = await getResponsibleAgentRegionService(payload);

    dispatch({
      type: GET_RESPONSIBLE_AGENT_REGION_SUCCESS,
      payload: res.data,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_RESPONSIBLE_AGENT_REGION_FAILURE,
      message: e.response.data.message,
    });
  }
};

export const getNotifications = () => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_NOTIFICATION,
    });

    const res = await getNotificationsService();

    dispatch({
      type: GET_NOTIFICATION_SUCCESS,
      payload: res.data,
      message: res.message,
      total: res.meta,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_NOTIFICATION_FAILURE,
    });
  }
};

export const actionMarkAsRead = (type: String, payload?: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: MARK_AS_READ,
    });

    let res: any = [];

    if (type === 'one') {
      res = await actionMarkAsDoneService(payload);
    }

    if (type === 'all') {
      res = await actionMarkAllAsDoneService();
    }

    dispatch({
      type: MARK_AS_READ_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: MARK_AS_READ_FAILURE,
      message: 'Something wrong',
    });
  }
};

export const GetProductSettings = (payload: any, type: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_PRODUCT_CATEGORY,
    });

    let res: any = [];
    if (type === SETTING_TYPE_PRODUCT_MEMBERSHIP || type === SETTING_TYPE_PRODUCT_ALACARTE) {
      res = await getProductService(payload);
    }
    if (type === SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await getProductUpgradeService(payload);
    }

    dispatch({
      type: GET_PRODUCT_CATEGORY_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_PRODUCT_CATEGORY_FAILURE,
    });
  }
};

export const updateFilterSettings = payload => (dispatch: Dispatch<SettingDispatchTypes>) => {
  return dispatch({
    type: UPDATE_PRODUCT_SETTING_FILTER,
    payload: payload,
  });
};

export const createProductAction = (data: any, type: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_PRODUCT,
    });

    let res: any = [];
    if (type === SETTING_TYPE_PRODUCT_MEMBERSHIP || type === SETTING_TYPE_PRODUCT_ALACARTE) {
      res = await createProductService(data);
    }
    if (type === SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await createProductUpgradeService(data);
    }
    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_PRODUCT_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const getDetailProduct = (id: any, type: any, payload: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_PRODUCT,
    });
    let res: any = [];
    let result;
    if (type == SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await getProductUpgradeDetailService(payload);
      result = res.data;
    } else {
      res = await getProductDetailService(id);
      result = res.message;
    }
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: result,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: GET_PRODUCT_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const updateProduct = (id: any, data: any, type: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT,
    });
    let res: any = [];
    if (type === SETTING_TYPE_PRODUCT_MEMBERSHIP || type === SETTING_TYPE_PRODUCT_ALACARTE) {
      res = await updateProductService(id, data);
    }
    if (type === SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await updateProductUpgradeService(data);
    }
    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_PRODUCT_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const deleteProductAction = (id: number, type: any, data: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_PRODUCT,
    });

    let res: any = [];
    if (type == SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await deleteProductUpgradeService(data);
    } else {
      res = await deleteProductService(id);
    }

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      message: e.message,
    });
  }
};

export const updateStatusProductAction = (id: number, data: any, type) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_STATUS_PRODUCT,
    });
    let res: any = [];
    if (type === SETTING_TYPE_PRODUCT_MEMBERSHIP || type === SETTING_TYPE_PRODUCT_ALACARTE) {
      res = await updateStatusProductService(id);
    }
    if (type === SETTING_TYPE_PRODUCT_UPGRADE) {
      res = await updateStatusProductUpgradeService(data);
    }

    dispatch({
      type: UPDATE_STATUS_PRODUCT_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_STATUS_PRODUCT_FAILURE,
      message: e.message,
    });
  }
};

export const getUpgradePackageDiscountType = (payload: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE,
    });

    const res = await getUpgradeDiscountTypeService(payload);
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const createUpgradeDiscountTypeAction = (data: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  let res;
  try {
    dispatch({
      type: CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
    });

    res = await createUpgradeDiscountTypeService(data);
    dispatch({
      type: CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
      message: e.message,
    });
    return e;
  }
};
export const updateStatusDiscountTypeAction = (id: number) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  let res;
  try {
    dispatch({
      type: UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE,
    });

    res = await updateStatusDiscountTypeService(id);

    dispatch({
      type: UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
      message: e.message,
    });
  }
};
export const deleteProductUpgradeDiscountTypeAction = (id: number) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  let res;
  try {
    dispatch({
      type: DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
    });

    res = await deleteProductUpgradeDiscountTypeService(id);

    dispatch({
      type: DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
      message: e.message,
    });
  }
};

export const getDetailProductUpgradeDiscountType = (id: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL,
    });

    const res = await getProducUpgradeDiscountTypetDetailService(id);
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE,
      message: e.message,
    });
    return e;
  }
};
export const updateProductUpgradeDiscountType = (id: any, data: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
    });

    const res = await updateProductUpgradeDiscountTypeService(id, data);
    dispatch({
      type: UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const updateFilterUpgradeDiscountSettings = payload => (dispatch: Dispatch<SettingDispatchTypes>) => {
  return dispatch({
    type: UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER,
    payload: payload,
  });
};

export const updateActivePackageAction = (leadId: any, bodyData: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_ACTIVE_PACKAGE,
    });

    let res: any = [];
    res = await updateActivePackageService(leadId, bodyData);

    dispatch({
      type: CREATE_PRODUCT_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_PRODUCT_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const createCompanyMappingAction = (data: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_COMPANY_MAPPING,
    });

    let res: any = [];
    res = await createCompanyMappingService(data);

    dispatch({
      type: CREATE_COMPANY_MAPPING_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_COMPANY_MAPPING_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const updateCompanyMappingAction = (id: any, data: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_COMPANY_MAPPING,
    });

    const res = await updateCompanyMappingService(id, data);
    dispatch({
      type: UPDATE_COMPANY_MAPPING_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_COMPANY_MAPPING_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const deleteCompanyMapping = (id: number) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_COMPANY_MAPPING,
    });

    let res: any = [];
    res = await deleteCompanyMappingService(id);

    dispatch({
      type: DELETE_COMPANY_MAPPING_SUCCESS,
      payload: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_COMPANY_MAPPING_FAILURE,
      message: e.message,
    });
  }
};

export const GetCompanyMapping = (payload: any) => async (dispatch: Dispatch<SettingDispatchTypes>) => {
  try {
    dispatch({
      type: GET_COMPANY_MAPPING,
    });

    let res: any = [];
    res = await getCompanyMappingService(payload);

    dispatch({
      type: GET_COMPANY_MAPPING_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_COMPANY_MAPPING_FAILURE,
      message: e.message,
    });
    return e;
  }
};
