import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@redux/hooks';
import { RootState } from '@redux/rootReducer';
import { fetchMenus, selectMenuStatus, selectMenus } from '@services/settings/menu.slice';
import { EnumRequestState } from '@services/settings/type/type';

export const useMenu = (isFirstLoad = false) => {
  const dispatch = useAppDispatch();
  const status = useSelector((state: RootState) => selectMenuStatus(state));
  const data = useSelector((state: RootState) => selectMenus(state));

  useEffect(() => {
    if (data === undefined && isFirstLoad && (status === undefined || status === EnumRequestState.rejected)) {
      dispatch(fetchMenus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, isFirstLoad]);

  const refetchMenus = () => {
    dispatch(fetchMenus());
  };

  return {
    data,
    status,
    refetchMenus,
  };
};
