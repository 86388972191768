import {
  ArchivedLeadsDispatchTypes,
  GET_ARCHIVED_LEADS,
  GET_ARCHIVED_LEADS_FAILURE,
  GET_ARCHIVED_LEADS_SUCCESS,
  leadsType,
  UNARCHIVE_LEADS,
  UNARCHIVE_LEADS_FAILURE,
  UNARCHIVE_LEADS_SUCCESS,
  UPDATE_FILTER_ARCHIVED_LEADS,
} from './archive-types';

export interface DefaultStateI {
  loading: boolean;
  leads?: leadsType;
  loadingUnarchiveLeads: boolean;
  successUnarchiveLeads: boolean;
  errorUnarchiveLeads: boolean;
  unarchiveLeadsData: any;
  unarchiveLeadsMessage: string;

  filterQuery: any;
}

const defaultState: DefaultStateI = {
  loading: false,
  leads: {
    data: [],
  },
  loadingUnarchiveLeads: false,
  successUnarchiveLeads: false,
  errorUnarchiveLeads: false,
  unarchiveLeadsData: {},
  unarchiveLeadsMessage: '',

  filterQuery: {},
};

const archivedLeadsReducer = (state: DefaultStateI = defaultState, action: ArchivedLeadsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_ARCHIVED_LEADS:
      return {
        ...state,
        loading: true,
      };
    case GET_ARCHIVED_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case GET_ARCHIVED_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UNARCHIVE_LEADS:
      return {
        ...state,
        loadingUnarchiveLeads: true,
        errorUnarchiveLeads: false,
        successUnarchiveLeads: false,
      };
    case UNARCHIVE_LEADS_SUCCESS:
      return {
        ...state,
        loadingUnarchiveLeads: false,
        errorUnarchiveLeads: false,
        successUnarchiveLeads: true,
        unarchiveLeadsData: action.payload,
      };
    case UNARCHIVE_LEADS_FAILURE:
      return {
        ...state,
        loadingUnarchiveLeads: false,
        errorUnarchiveLeads: true,
        successUnarchiveLeads: false,
        unarchiveLeadsMessage: action.message,
      };
    case UPDATE_FILTER_ARCHIVED_LEADS:
      return {
        ...state,
        filterQuery: action.payload,
      };
    default:
      return state;
  }
};

export default archivedLeadsReducer;
