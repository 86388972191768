import { Dispatch } from 'redux';
import { GET_PERMISSION, GET_PERMISSION_SUCCESS, GET_PERMISSION_FAILURE, PermissionDispatchTypes } from './permission-type';
import { getPermissions } from './permission-service';

export const GetPermissions = () => async (dispatch: Dispatch<PermissionDispatchTypes>) => {
  try {
    dispatch({
      type: GET_PERMISSION,
    });

    const results = await getPermissions();

    dispatch({
      type: GET_PERMISSION_SUCCESS,
      payload: results.data,
    });

    return results.data;
  } catch (e: any) {
    dispatch({
      type: GET_PERMISSION_FAILURE,
    });
  }
};
