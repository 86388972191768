import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  UserDispatchTypes,
} from './auth-types';

interface DefaultStateI {
  loading: boolean;
  user?: any;
  error?: boolean;
  messages?: any;
}

const defaultState: DefaultStateI = {
  loading: false,
};

const authReducer = (state: DefaultStateI = defaultState, action: UserDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        loading: true,
        error: false,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        error: false,
        user: action.payload,
        messages: null,
      };
    case USER_LOGIN_FAILURE:
      return {
        loading: false,
        error: true,
        messages: action.messages,
      };
    case USER_LOGOUT:
      return {
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
      };
    case USER_LOGOUT_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
