import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from './type/type';
import { MenuInterface } from './type/menu.type';
import { getMenus } from 'src/redux/app/menu/menu-service';
import { generateMenu } from './helper/menu.mapper';
import { RootState } from 'src/redux/rootReducer';

export const fetchMenus = createAsyncThunk<MenuInterface[]>('menu/get-all', async (_, { rejectWithValue }) => {
  const response = await getMenus();
  if (response?.status_code !== 200) {
    rejectWithValue(response);
  }
  return response?.data ? generateMenu(response?.data) : null;
});

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    data: undefined as MenuInterface[],
    status: undefined as RequestState,
    loading: false as boolean,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMenus.pending, state => {
      state.status = 'pending';
      state.loading = true;
    });

    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchMenus.rejected, state => {
      state.status = 'rejected';
      state.data = null;
      state.loading = false;
    });
  },
});

export const selectMenus = (state: RootState) => state.appMenus.data;
export const selectMenuStatus = (state: RootState) => state.appMenus.status;
export const { actions, reducer } = menuSlice;
export default reducer;
