import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LeadsSettingsInterface, LeadTransactionSchemaInterface } from './interface/leads.interface';
import { getTransactionSchemaService } from './leads.service';
import { RequestState } from '@services/settings/type/type';
import { RootState } from '@redux/rootReducer';

export const fetchLeadsTransactionSchema = createAsyncThunk<LeadTransactionSchemaInterface[]>(
  'leads/get-lead-transaction-schema',
  async (_, { rejectWithValue }) => {
    const response = await getTransactionSchemaService();
    if (response?.status_code !== 200) {
      rejectWithValue(response);
    }
    return response?.data || null;
  },
);

export const leadSlice = createSlice({
  name: 'leads',
  initialState: {
    status: undefined as RequestState,
    settings: undefined as LeadsSettingsInterface,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLeadsTransactionSchema.pending, state => {
      state.status = 'pending';
      state.settings = {
        ...state.settings,
        loading: true,
      };
    });

    builder.addCase(fetchLeadsTransactionSchema.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.settings = {
        ...state.settings,
        transactionSchema: action.payload,
        loading: false,
      };
    });
    builder.addCase(fetchLeadsTransactionSchema.rejected, state => {
      state.status = 'rejected';
      state.settings = {
        ...state.settings,
        transactionSchema: undefined,
        loading: false,
      };
    });
  },
});

export const selectLeadsSettings = (state: RootState) => state.leadsV2?.settings;
export const selectLeadsStatus = (state: RootState) => state.leadsV2?.status;
export const { actions, reducer } = leadSlice;
export default reducer;
