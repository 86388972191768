import { Badge, Col, Row } from 'antd';
import React from 'react';
import { NotificationContentParams } from '.';
import { BellFilled } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { actionMarkAsRead } from '@redux/settings/setting-action';
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_LEADER, TYPE_DISCOUNT, TYPE_PAYMENT } from './constant/notification.constant';

export const NotificationContent: React.FC<NotificationContentParams> = ({
  data,
  i,
  reloadNotifications,
  onCloseDrawer,
  markAllAsRead,
}: NotificationContentParams) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let notificationBody = {};
  let notification_desc = ``;
  let linkRedirect = ``;

  const { type, type_name, is_read, sender, value, created_at, user } = data;
  let { fullname } = sender;
  let { status_id, discount_approval_transaction_package, payment_approval, lead } = value;

  const typeName = type_name.split(' ');

  const asReadNotification = async () => {
    if (!is_read) {
      await dispatch(actionMarkAsRead('one', notificationBody));
    }
    reloadNotifications();
  };

  const handleNotification = async () => {
    asReadNotification();
    history.push(data.link);
    onCloseDrawer();
  };

  const descriptionApproval = async (item: any) => {
    let status_name = ``;
    let subject = ``;
    switch (status_id) {
      case TYPE_DISCOUNT:
        status_name = `approved`;
        subject = type === TYPE_DISCOUNT ? item?.product_package_name : `confirmation`;
        notification_desc = `${fullname} has ${status_name} a ${typeName[0].toLowerCase()} for ${subject}`;
        linkRedirect = `/leads?id=${item?.lead_id}&page=1`;
        break;
      case TYPE_PAYMENT:
        status_name = type === TYPE_DISCOUNT ? `rejected` : `declined`;
        subject = type === TYPE_DISCOUNT ? item?.product_package_name : `confirmation`;
        notification_desc = `${fullname} has ${status_name} a ${typeName[0].toLowerCase()} for ${subject}`;
        linkRedirect = `/leads?id=${item?.lead_id}&page=1`;
        break;
      default:
        status_name = type === TYPE_DISCOUNT ? `requested` : `submitted`;
        subject = type === TYPE_DISCOUNT ? item?.product_package_name : `confirmation`;
        notification_desc = `${fullname} has ${status_name} an approval ${typeName[0].toLowerCase()} for ${subject}`;
        linkRedirect = type === TYPE_DISCOUNT ? `/my-approval/leads` : `/my-approval/payment`;
        linkRedirect += `?${item.param}=${notificationBody['value'][`${item.param}`]}&page=1`;
        break;
    }
    return (data.link = linkRedirect);
  };

  const descriptionRegister = async (item: any) => {
    if (user?.role_id === ROLE_LEADER) {
      //role is leader
      if (item?.lead_owner_id) {
        notification_desc = `Your ${item?.lead_owner?.fullname} have a new agent from sites Rumah123`;
      } else {
        notification_desc = `You have a new unassigned agent from sites Rumah123`;
      }
    } else if (user?.role_id === ROLE_ADMIN) {
      //role is administrator
      if (item?.lead_owner_id) {
        notification_desc = `Your ${item?.lead_owner?.fullname} have a new agent from sites Rumah123`;
      } else {
        notification_desc = `You have a new unassigned agent from sites Rumah123`;
      }
    } else {
      //role is membership
      if (item?.lead_owner_id) {
        notification_desc = `You have a new unassigned agent from sites Rumah123`;
      }
    }
    linkRedirect = `/leads?id=${item?.lead_id}&page=1`;
    return (data.link = linkRedirect);
  };

  switch (type) {
    case 1:
      notificationBody = {
        user_id: user?.user_id,
        notification_type: type,
        value: {
          discount_approval_id: value?.discount_approval_transaction_package.discount_approval_id,
        },
      };
      discount_approval_transaction_package.param = `discount_approval_id`;
      discount_approval_transaction_package.lead_id = value?.lead_id;
      descriptionApproval(discount_approval_transaction_package);
      break;
    case 2:
      notificationBody = {
        user_id: user?.user_id,
        notification_type: type,
        value: {
          payment_approval_id: value?.payment_approval?.id,
        },
      };
      payment_approval.param = `payment_approval_id`;
      payment_approval.lead_id = payment_approval?.lead_id;
      descriptionApproval(payment_approval);
      break;
    case 3:
      notificationBody = {
        user_id: user?.user_id,
        notification_type: type,
        value: {
          lead_id: value?.lead_id,
        },
      };
      lead.param = `id`;
      lead.lead_id = value?.lead_id;
      descriptionRegister(lead);
      break;
    default:
      break;
  }
  return (
    <Row style={{ paddingBottom: '8px' }}>
      <Col lg={24} className={!is_read ? 'notification-item notification-active' : 'notification-item'} onClick={handleNotification}>
        <div className='notification-item-info'>
          <span className='notification-item-info-title'>
            <Badge dot={!is_read ? true : false}>
              <BellFilled className='notification-item-info-icon' />
            </Badge>{' '}
            {type_name.toUpperCase()}
          </span>
          <span className='notification-item-info-date'>
            {data &&
              moment(data && data.created_at && created_at)
                .tz('Asia/Jakarta')
                .calendar({
                  sameDay: '[Today], HH:mm',
                  lastDay: '[Yesterday], HH:mm',
                  lastWeek: 'DD MMMM YYYY, HH:mm',
                  sameElse: 'DD MMMM YYYY, HH:mm',
                })}
          </span>
        </div>
        <div className='notification-item-desc'>
          <span>{notification_desc}</span>
        </div>
      </Col>
    </Row>
  );
};
