import { GET_PERMISSION, GET_PERMISSION_SUCCESS, GET_PERMISSION_FAILURE, PermissionDispatchTypes, permissionType } from './permission-type';

interface DefaultStateI {
  loading: boolean;
  permissions?: permissionType;
}

const defaultState: DefaultStateI = {
  loading: false,
};

const appPermissionReducer = (state: DefaultStateI = defaultState, action: PermissionDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_PERMISSION:
      return {
        loading: true,
      };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload,
      };
    case GET_PERMISSION_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default appPermissionReducer;
