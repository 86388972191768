export const GET_PROVINCE = 'GET_PROVINCE';
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS';
export const GET_PROVINCE_FAILURE = 'GET_PROVINCE_FAILURE';
export const GET_CITY = 'GET_CITY';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';
export const GET_DISTRICT = 'GET_DISTRICT';
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_SUCCESS';
export const GET_DISTRICT_FAILURE = 'GET_DISTRICT_FAILURE';

export interface GetProvince {
  type: typeof GET_PROVINCE;
}

export interface GetProvinceSuccess {
  type: typeof GET_PROVINCE_SUCCESS;
  payload: any;
}

export interface GetProvinceFailure {
  type: typeof GET_PROVINCE_FAILURE;
}

export interface GetCity {
  type: typeof GET_CITY;
}

export interface GetCitySuccess {
  type: typeof GET_CITY_SUCCESS;
  payload: any;
}

export interface GetCityFailure {
  type: typeof GET_CITY_FAILURE;
}

export interface GetDistrict {
  type: typeof GET_DISTRICT;
}

export interface GetDistrictSuccess {
  type: typeof GET_DISTRICT_SUCCESS;
  payload: any;
}

export interface GetDistrictFailure {
  type: typeof GET_DISTRICT_FAILURE;
}

export type LocationDispatchTypes =
  | GetProvince
  | GetProvinceSuccess
  | GetProvinceFailure
  | GetCity
  | GetCitySuccess
  | GetCityFailure
  | GetDistrict
  | GetDistrictSuccess
  | GetDistrictFailure;
