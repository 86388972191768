export const GET_LEAD = 'GET_LEAD';
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_LEAD_TRANSACTION_HISTORY = 'GET_LEAD_TRANSACTION_HISTORY';
export const GET_LEAD_TRANSACTION_HISTORY_SUCCESS = 'GET_LEAD_TRANSACTION_HISTORY_SUCCESS';
export const GET_LEAD_TRANSACTION_HISTORY_FAILURE = 'GET_LEAD_TRANSACTION_HISTORY_FAILURE';
export const GET_STATUS_CALL_SUCCESS = 'GET_STATUS_CALL_SUCCESS';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';
export const UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE';
export const UPDATE_LEAD_ACTIVITY = 'UPDATE_LEAD_ACTIVITY';
export const UPDATE_LEAD_ACTIVITY_SUCCESS = 'UPDATE_LEAD_ACTIVITY_SUCCESS';
export const UPDATE_LEAD_ACTIVITY_FAILURE = 'UPDATE_LEAD_ACTIVITY_FAILURE';
export const GET_LEAD_ACTIVITY_HISTORY = 'GET_LEAD_ACTIVITY_HISTORY';
export const GET_LEAD_ACTIVITY_HISTORY_SUCCESS = 'GET_LEAD_ACTIVITY_HISTORY_SUCCESS';
export const GET_LEAD_ACTIVITY_HISTORY_FAILURE = 'GET_LEAD_ACTIVITY_HISTORY_FAILURE';
export const UPDATE_ACTIVITY_HISTORY = 'UPDATE_ACTIVITY_HISTORY';
export const UPDATE_ACTIVITY_HISTORY_SUCCESS = 'UPDATE_ACTIVITY_HISTORY_SUCCESS';
export const UPDATE_ACTIVITY_HISTORY_FAILURE = 'UPDATE_ACTIVITY_HISTORY_FAILURE';
export const UPDATE_ACTIVITY_DONE = 'UPDATE_ACTIVITY_DONE';
export const UPDATE_ACTIVITY_DONE_SUCCESS = 'UPDATE_ACTIVITY_DONE_SUCCESS';
export const UPDATE_ACTIVITY_DONE_FAILURE = 'UPDATE_ACTIVITY_DONE_FAILURE';
export const CREATE_SCHEDULE_ACTIVITY = 'CREATE_SCHEDULE_ACTIVITY';
export const CREATE_SCHEDULE_ACTIVITY_SUCCESS = 'CREATE_SCHEDULE_ACTIVITY_SUCCESS';
export const CREATE_SCHEDULE_ACTIVITY_FAILURE = 'CREATE_SCHEDULE_ACTIVITY_FAILURE';
export const CREATE_SENT_AE = 'CREATE_SENT_AE';
export const CREATE_SENT_AE_SUCCESS = 'CREATE_SENT_AE_SUCCESS';
export const CREATE_SENT_AE_FAILURE = 'CREATE_SENT_AE_FAILURE';
export const CREATE_LEAD = 'CREATE_LEAD';
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE';

export const DELETE_LEAD_PRODUCT = 'DELETE_LEAD_PRODUCT';
export const DELETE_LEAD_PRODUCT_SUCCESS = 'DELETE_LEAD_PRODUCT_SUCCESS';
export const DELETE_LEAD_PRODUCT_FAILURE = 'DELETE_LEAD_PRODUCT_FAILURE';

export const CREATE_ASSIGN_MAPPING_LOCATION_AE = 'CREATE_ASSIGN_MAPPING_LOCATION_AE';
export const CREATE_ASSIGN_MAPPING_LOCATION_AE_SUCCESS = 'CREATE_ASSIGN_MAPPING_LOCATION_AE_SUCCESS';
export const CREATE_ASSIGN_MAPPING_LOCATION_AE_FAILURE = 'CREATE_ASSIGN_MAPPING_LOCATION_AE_FAILURE';

export const GET_CALCULATION_PACKAGE = 'GET_CALCULATION_PACKAGE';
export const GET_CALCULATION_PACKAGE_SUCCESS = 'GET_CALCULATION_PACKAGE_SUCCESS';
export const GET_CALCULATION_PACKAGE_FAILURE = 'GET_CALCULATION_PACKAGE_FAILURE';

export const SENT_INVOICE = 'SENT_INVOICE';
export const SENT_INVOICE_SUCCESS = 'SENT_INVOICE_SUCCESS';
export const SENT_INVOICE_FAILURE = 'SENT_INVOICE_FAILURE';

export const GET_CALCULATION_TRANSACTION = 'GET_CALCULATION_TRANSACTION';
export const GET_CALCULATION_TRANSACTION_SUCCESS = 'GET_CALCULATION_TRANSACTION_SUCCESS';
export const GET_CALCULATION_TRANSACTION_FAILURE = 'GET_CALCULATION_TRANSACTION_FAILURE';

export const UPDATE_LEAD_VERIFY = 'UPDATE_LEAD_VERIFY';
export const UPDATE_LEAD_VERIFY_SUCCESS = 'UPDATE_LEAD_VERIFY_SUCCESS';
export const UPDATE_LEAD_VERIFY_FAILURE = 'UPDATE_LEAD_VERIFY_FAILURE';

export interface GetCalculationPackage {
  type: typeof GET_CALCULATION_PACKAGE;
}

export interface GetCalculationPackageSuccess {
  type: typeof GET_CALCULATION_PACKAGE_SUCCESS;
  payload: any;
}

export interface GetCalculationPackageFailure {
  type: typeof GET_CALCULATION_PACKAGE_FAILURE;
  message: any;
}

export interface GetLead {
  type: typeof GET_LEAD;
}

export interface GetLeadSuccess {
  type: typeof GET_LEAD_SUCCESS;
  payload: any;
}

export interface GetLeadFailure {
  type: typeof GET_LEAD_FAILURE;
  message: any;
}

export interface GetCampaign {
  type: typeof GET_CAMPAIGN;
}

export interface GetCampaignSuccess {
  type: typeof GET_CAMPAIGN_SUCCESS;
  payload: any;
}

export interface GetCampaignFailure {
  type: typeof GET_CAMPAIGN_FAILURE;
}

export interface GetLeadTransactionHistory {
  type: typeof GET_LEAD_TRANSACTION_HISTORY;
}

export interface GetLeadTransactionHistorySuccess {
  type: typeof GET_LEAD_TRANSACTION_HISTORY_SUCCESS;
  payload: any;
}

export interface GetLeadTransactionHistoryFailure {
  type: typeof GET_LEAD_TRANSACTION_HISTORY_FAILURE;
}

export interface GetLeadActivityHistory {
  type: typeof GET_LEAD_ACTIVITY_HISTORY;
}

export interface GetLeadActivityHistorySuccess {
  type: typeof GET_LEAD_ACTIVITY_HISTORY_SUCCESS;
  payload: any;
}

export interface GetLeadActivityHistoryFailure {
  type: typeof GET_LEAD_ACTIVITY_HISTORY_FAILURE;
  message: any;
}

export interface UpdateActivityHistory {
  type: typeof UPDATE_ACTIVITY_HISTORY;
}

export interface UpdateActivityHistorySuccess {
  type: typeof UPDATE_ACTIVITY_HISTORY_SUCCESS;
  payload: any;
}

export interface UpdateActivityHistoryFailure {
  type: typeof UPDATE_ACTIVITY_HISTORY_FAILURE;
}

export interface UpdateActivityHistoryDone {
  type: typeof UPDATE_ACTIVITY_DONE;
}

export interface UpdateActivityHistoryDoneSuccess {
  type: typeof UPDATE_ACTIVITY_DONE_SUCCESS;
}

export interface UpdateActivityHistoryDoneFailure {
  type: typeof UPDATE_ACTIVITY_DONE_FAILURE;
}

export interface updateLead {
  type: typeof UPDATE_LEAD;
}

export interface updateLeadSuccess {
  type: typeof UPDATE_LEAD_SUCCESS;
  payload: any;
}

export interface updateLeadFailure {
  type: typeof UPDATE_LEAD_FAILURE;
  message: any;
}

export interface UpdateLeadActivity {
  type: typeof UPDATE_LEAD_ACTIVITY;
}

export interface UpdateLeadActivitySuccess {
  type: typeof UPDATE_LEAD_ACTIVITY_SUCCESS;
  payload: any;
}

export interface UpdateLeadActivityFailure {
  type: typeof UPDATE_LEAD_ACTIVITY_FAILURE;
}

export interface createLead {
  type: typeof CREATE_LEAD;
}

export interface createLeadSuccess {
  type: typeof CREATE_LEAD_SUCCESS;
  payload: any;
}
export interface createLeadFailure {
  type: typeof CREATE_LEAD_FAILURE;
  message: any;
}

export interface getStatusCall {
  type: typeof GET_STATUS_CALL_SUCCESS;
  payload: any;
}

export interface CreateScheduleActivity {
  type: typeof CREATE_SCHEDULE_ACTIVITY;
}

export interface CreateScheduleActivitySuccess {
  type: typeof CREATE_SCHEDULE_ACTIVITY_SUCCESS;
  payload: any;
}

export interface CreateScheduleActivityFailure {
  type: typeof CREATE_SCHEDULE_ACTIVITY_FAILURE;
}

export interface CreateSentAE {
  type: typeof CREATE_SENT_AE;
}

export interface CreateSentAESuccess {
  type: typeof CREATE_SENT_AE_SUCCESS;
  payload: any;
}

export interface CreateSentAEFailure {
  type: typeof CREATE_SENT_AE_FAILURE;
}

export interface DeleteLeadProduct {
  type: typeof DELETE_LEAD_PRODUCT;
}

export interface DeleteLeadProductSuccess {
  type: typeof DELETE_LEAD_PRODUCT_SUCCESS;
  message: any;
}

export interface DeleteLeadProductFailure {
  type: typeof DELETE_LEAD_PRODUCT_FAILURE;
  message: any;
}

export interface CreateAssignMappingLocationAE {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION_AE;
}

export interface CreateAssignMappingLocationAESuccess {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION_AE_SUCCESS;
  payload: any;
  message: string;
}

export interface CreateAssignMappingLocationAEFailure {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION_AE_FAILURE;
  message: string;
}

export interface SentInvoice {
  type: typeof SENT_INVOICE;
}

export interface SentInvoiceSuccess {
  type: typeof SENT_INVOICE_SUCCESS;
  message: any;
}

export interface SentInvoiceFailure {
  type: typeof SENT_INVOICE_FAILURE;
  message: any;
}
export interface GetCalculationTransaction {
  type: typeof GET_CALCULATION_TRANSACTION;
}

export interface GetCalculationTransactionSuccess {
  type: typeof GET_CALCULATION_TRANSACTION_SUCCESS;
  payload: any;
}

export interface GetCalculationTransactionFailure {
  type: typeof GET_CALCULATION_TRANSACTION_FAILURE;
  message: any;
}

export interface updateLeadVerify {
  type: typeof UPDATE_LEAD_VERIFY;
}

export interface updateLeadVerifySuccess {
  type: typeof UPDATE_LEAD_VERIFY_SUCCESS;
  payload: any;
}

export interface updateLeadVerifyFailure {
  type: typeof UPDATE_LEAD_VERIFY_FAILURE;
  message: any;
}

export type LeadDispatchTypes =
  | GetLead
  | GetLeadFailure
  | GetLeadSuccess
  | GetCampaign
  | GetCampaignFailure
  | GetCampaignSuccess
  | GetLeadTransactionHistory
  | GetLeadTransactionHistorySuccess
  | GetLeadTransactionHistoryFailure
  | updateLead
  | updateLeadSuccess
  | updateLeadFailure
  | getStatusCall
  | GetLeadActivityHistory
  | GetLeadActivityHistorySuccess
  | GetLeadActivityHistoryFailure
  | UpdateActivityHistory
  | UpdateActivityHistorySuccess
  | UpdateActivityHistoryFailure
  | UpdateActivityHistoryDone
  | UpdateActivityHistoryDoneSuccess
  | UpdateActivityHistoryDoneFailure
  | CreateScheduleActivity
  | CreateScheduleActivitySuccess
  | CreateScheduleActivityFailure
  | CreateSentAE
  | CreateSentAESuccess
  | CreateSentAEFailure
  | createLead
  | createLeadSuccess
  | createLeadFailure
  | DeleteLeadProduct
  | DeleteLeadProductSuccess
  | DeleteLeadProductFailure
  | CreateAssignMappingLocationAE
  | CreateAssignMappingLocationAESuccess
  | CreateAssignMappingLocationAEFailure
  | GetCalculationPackage
  | GetCalculationPackageSuccess
  | GetCalculationPackageFailure
  | UpdateLeadActivity
  | UpdateLeadActivitySuccess
  | UpdateLeadActivityFailure
  | SentInvoice
  | SentInvoiceSuccess
  | SentInvoiceFailure
  | GetCalculationTransaction
  | GetCalculationTransactionSuccess
  | GetCalculationTransactionFailure
  | updateLeadVerify
  | updateLeadVerifySuccess
  | updateLeadVerifyFailure;
