export const GET_RESPONSIBLE_AGENT_AREA = 'GET_RESPONSIBLE_AGENT_AREA';
export const GET_RESPONSIBLE_AGENT_AREA_SUCCESS = 'GET_RESPONSIBLE_AGENT_AREA_SUCCESS';
export const GET_RESPONSIBLE_AGENT_AREA_FAILURE = 'GET_RESPONSIBLE_AGENT_AREA_FAILURE';
export const GET_RESPONSIBLE_AGENT = 'GET_RESPONSIBLE_AGENT';
export const GET_RESPONSIBLE_AGENT_SUCCESS = 'GET_RESPONSIBLE_AGENT_SUCCESS';
export const GET_RESPONSIBLE_AGENT_FAILURE = 'GET_RESPONSIBLE_AGENT_FAILURE';
export const GET_IS_LOCATION_MAPPED = 'GET_IS_LOCATION_MAPPED';
export const GET_IS_LOCATION_MAPPED_SUCCESS = 'GET_IS_LOCATION_MAPPED_SUCCESS';
export const GET_IS_LOCATION_MAPPED_FAILURE = 'GET_IS_LOCATION_MAPPED_FAILURE';
export const GET_RESPONSIBLE_AGENT_REGION = 'GET_RESPONSIBLE_AGENT_REGION';
export const GET_RESPONSIBLE_AGENT_REGION_SUCCESS = 'GET_RESPONSIBLE_AGENT_REGION_SUCCESS';
export const GET_RESPONSIBLE_AGENT_REGION_FAILURE = 'GET_RESPONSIBLE_AGENT_REGION_FAILURE';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILURE = 'MARK_AS_READ_FAILURE';
export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS';
export const GET_PRODUCT_CATEGORY_FAILURE = 'GET_PRODUCT_CATEGORY_FAILURE';
export const UPDATE_PRODUCT_SETTING_FILTER = 'UPDATE_PRODUCT_SETTING_FILTER';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export const UPDATE_STATUS_PRODUCT = 'UPDATE_STATUS_PRODUCT';
export const UPDATE_STATUS_PRODUCT_SUCCESS = 'UPDATE_STATUS_PRODUCT_SUCCESS';
export const UPDATE_STATUS_PRODUCT_FAILURE = 'UPDATE_STATUS_PRODUCT_FAILURE';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE';
export const CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE = 'CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE';
export const CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS = 'CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS';
export const CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE = 'CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE';
export const UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE = 'UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE';
export const UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS = 'UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS';
export const UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE = 'UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE';
export const DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE = 'DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE';
export const DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS = 'DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS';
export const DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE = 'DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS';
export const GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE = 'GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE';
export const UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE = 'UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE';
export const UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS = 'UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS';
export const UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE = 'UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE';
export const UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER = 'UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER';
export const UPDATE_ACTIVE_PACKAGE = 'UPDATE_ACTIVE_PACKAGE';
export const UPDATE_ACTIVE_PACKAGE_SUCCESS = 'UPDATE_ACTIVE_PACKAGE_SUCCESS';
export const UPDATE_ACTIVE_PACKAGE_FAILURE = 'UPDATE_ACTIVE_PACKAGE_FAILURE';
export const CREATE_COMPANY_MAPPING = 'CREATE_COMPANY_MAPPING';
export const CREATE_COMPANY_MAPPING_SUCCESS = 'CREATE_COMPANY_MAPPING_SUCCESS';
export const CREATE_COMPANY_MAPPING_FAILURE = 'CREATE_COMPANY_MAPPING_FAILURE';
export const UPDATE_COMPANY_MAPPING = 'UPDATE_COMPANY_MAPPING';
export const UPDATE_COMPANY_MAPPING_SUCCESS = 'UPDATE_COMPANY_MAPPING_SUCCESS';
export const UPDATE_COMPANY_MAPPING_FAILURE = 'UPDATE_COMPANY_MAPPING_FAILURE';
export const DELETE_COMPANY_MAPPING = 'DELETE_COMPANY_MAPPING';
export const DELETE_COMPANY_MAPPING_SUCCESS = 'DELETE_COMPANY_MAPPING_SUCCESS';
export const DELETE_COMPANY_MAPPING_FAILURE = 'DELETE_COMPANY_MAPPING_FAILURE';
export const GET_COMPANY_MAPPING = 'GET_COMPANY_MAPPING';
export const GET_COMPANY_MAPPING_SUCCESS = 'GET_COMPANY_MAPPING_SUCCESS';
export const GET_COMPANY_MAPPING_FAILURE = 'GET_COMPANY_MAPPING_FAILURE';

export interface GetResponsibleAgentArea {
  type: typeof GET_RESPONSIBLE_AGENT_AREA;
}

export interface GetResponsibleAgentAreaSuccess {
  type: typeof GET_RESPONSIBLE_AGENT_AREA_SUCCESS;
  payload: any;
}

export interface GetResponsibleAgentAreaFailure {
  type: typeof GET_RESPONSIBLE_AGENT_AREA_FAILURE;
}

export interface GetResponsibleAgent {
  type: typeof GET_RESPONSIBLE_AGENT;
}

export interface GetResponsibleAgentSuccess {
  type: typeof GET_RESPONSIBLE_AGENT_SUCCESS;
  payload: any;
}

export interface GetResponsibleAgentFailure {
  type: typeof GET_RESPONSIBLE_AGENT_FAILURE;
}

export interface GetIsLocationMapped {
  type: typeof GET_IS_LOCATION_MAPPED;
}

export interface GetIsLocationMappedSuccess {
  type: typeof GET_IS_LOCATION_MAPPED_SUCCESS;
  payload: any;
  message: string;
}

export interface GetIsLocationMappedFailure {
  type: typeof GET_IS_LOCATION_MAPPED_FAILURE;
  message: string;
}

export interface GetResponsibleAgentRegion {
  type: typeof GET_RESPONSIBLE_AGENT_REGION;
}

export interface GetResponsibleAgentRegionSuccess {
  type: typeof GET_RESPONSIBLE_AGENT_REGION_SUCCESS;
  payload: any;
  message: string;
}

export interface GetResponsibleAgentRegionFailure {
  type: typeof GET_RESPONSIBLE_AGENT_REGION_FAILURE;
  message: string;
}

export interface GetNotification {
  type: typeof GET_NOTIFICATION;
}

export interface GetNotificationSuccess {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: any;
  total: any;
}

export interface GetNotificationFailure {
  type: typeof GET_NOTIFICATION_FAILURE;
}

export interface MarkAsRead {
  type: typeof MARK_AS_READ;
}

export interface MarkAsReadSuccess {
  type: typeof MARK_AS_READ_SUCCESS;
  payload: any;
}

export interface MarkAsReadFailure {
  type: typeof MARK_AS_READ_FAILURE;
}

export type productSettings = {
  data: any;
};

export interface GetProductType {
  type: typeof GET_PRODUCT_CATEGORY;
}
export interface GetProductTypeSuccess {
  type: typeof GET_PRODUCT_CATEGORY_SUCCESS;
  payload: any;
}
export interface GetProductTypeFailure {
  type: typeof GET_PRODUCT_CATEGORY_FAILURE;
}
export interface UpdateFilterProduct {
  type: typeof UPDATE_PRODUCT_SETTING_FILTER;
  payload: any;
}

export interface createProduct {
  type: typeof CREATE_PRODUCT;
}

export interface createProductSuccess {
  type: typeof CREATE_PRODUCT_SUCCESS;
  payload: any;
}
export interface createProductFailure {
  type: typeof CREATE_PRODUCT_FAILURE;
  message: any;
}
export interface GetProduct {
  type: typeof GET_PRODUCT;
}

export interface GetProductSuccess {
  type: typeof GET_PRODUCT_SUCCESS;
  payload: any;
}

export interface GetProductFailure {
  type: typeof GET_PRODUCT_FAILURE;
  message: any;
}

export interface updateProduct {
  type: typeof UPDATE_PRODUCT;
}

export interface updateProductSuccess {
  type: typeof UPDATE_PRODUCT_SUCCESS;
  payload: any;
}

export interface updateProductFailure {
  type: typeof UPDATE_PRODUCT_FAILURE;
  message: any;
}

export interface updateActivePackage {
  type: typeof UPDATE_ACTIVE_PACKAGE;
}

export interface updateActivePackageSuccess {
  type: typeof UPDATE_ACTIVE_PACKAGE_SUCCESS;
  payload: any;
}

export interface updateActivePackageFailure {
  type: typeof UPDATE_ACTIVE_PACKAGE_FAILURE;
  message: any;
}

export interface DeleteProduct {
  type: typeof DELETE_PRODUCT;
}

export interface DeleteProductSuccess {
  type: typeof DELETE_PRODUCT_SUCCESS;
  message: any;
}

export interface DeleteProductFailure {
  type: typeof DELETE_PRODUCT_FAILURE;
  message: any;
}

export interface updateStatusProduct {
  type: typeof UPDATE_STATUS_PRODUCT;
}

export interface updateStatusProductSuccess {
  type: typeof UPDATE_STATUS_PRODUCT_SUCCESS;
  message: any;
}

export interface updateStatusProductFailure {
  type: typeof UPDATE_STATUS_PRODUCT_FAILURE;
  message: any;
}

export interface GetProductUpgradeDiscountType {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE;
}

export interface GetProductUpgradeDiscountTypeSuccess {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS;
  payload: any;
}

export interface GetProductUpgradeDiscountTypeFailure {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE;
  message: any;
}

export interface createProductUpgradeDiscountType {
  type: typeof CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE;
}

export interface createProductUpgradeDiscountTypeSuccess {
  type: typeof CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS;
  payload: any;
}
export interface createProductUpgradeDiscountTypeFailure {
  type: typeof CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE;
  message: any;
}
export interface updateStatusProductUpgradeDiscountType {
  type: typeof UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE;
}

export interface updateStatusProductUpgradeDiscountTypeSuccess {
  type: typeof UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS;
  message: any;
}

export interface updateStatusProductUpgradeDiscountTypeFailure {
  type: typeof UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE;
  message: any;
}

export interface DeleteProductUpgradeDiscountType {
  type: typeof DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE;
}

export interface DeleteProductUpgradeDiscountTypeSuccess {
  type: typeof DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS;
  message: any;
}

export interface DeleteProductUpgradeDiscountTypeFailure {
  type: typeof DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE;
  message: any;
}
export interface GetProductUpgradeDiscountTypeDetail {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL;
}

export interface GetProductUpgradeDiscountTypeDetailSuccess {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS;
  payload: any;
}

export interface GetProductUpgradeDiscountTypeDetailFailure {
  type: typeof GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE;
  message: any;
}
export interface updateProductUpgradeDiscountType {
  type: typeof UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE;
}

export interface updateProductUpgradeDiscountTypeSuccess {
  type: typeof UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS;
  payload: any;
}

export interface updateProductUpgradeDiscountTypeFailure {
  type: typeof UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE;
  message: any;
}
export interface UpdateFilterProductUpgradeDiscount {
  type: typeof UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER;
  payload: any;
}

export interface createCompanyMapping {
  type: typeof CREATE_COMPANY_MAPPING;
}

export interface createCompanyMappingSuccess {
  type: typeof CREATE_COMPANY_MAPPING_SUCCESS;
  payload: any;
}
export interface createCompanyMappingFailure {
  type: typeof CREATE_COMPANY_MAPPING_FAILURE;
  message: any;
}

export interface updateCompanyMapping {
  type: typeof UPDATE_COMPANY_MAPPING;
}

export interface updateCompanyMappingSuccess {
  type: typeof UPDATE_COMPANY_MAPPING_SUCCESS;
  payload: any;
}
export interface updateCompanyMappingFailure {
  type: typeof UPDATE_COMPANY_MAPPING_FAILURE;
  message: any;
}

export interface deleteCompanyMapping {
  type: typeof DELETE_COMPANY_MAPPING;
}

export interface deleteCompanyMappingSuccess {
  type: typeof DELETE_COMPANY_MAPPING_SUCCESS;
  payload: any;
}
export interface deleteCompanyMappingFailure {
  type: typeof DELETE_COMPANY_MAPPING_FAILURE;
  message: any;
}

export interface getCompanyMapping {
  type: typeof GET_COMPANY_MAPPING;
}

export interface getCompanyMappingSuccess {
  type: typeof GET_COMPANY_MAPPING_SUCCESS;
  payload: any;
}
export interface getCompanyMappingFailure {
  type: typeof GET_COMPANY_MAPPING_FAILURE;
  message: any;
}

export type SettingDispatchTypes =
  | GetResponsibleAgentArea
  | GetResponsibleAgentAreaSuccess
  | GetResponsibleAgentAreaFailure
  | GetResponsibleAgent
  | GetResponsibleAgentSuccess
  | GetResponsibleAgentFailure
  | GetIsLocationMapped
  | GetIsLocationMappedSuccess
  | GetIsLocationMappedFailure
  | GetResponsibleAgentRegion
  | GetResponsibleAgentRegionSuccess
  | GetResponsibleAgentRegionFailure
  | GetNotification
  | GetNotificationSuccess
  | GetNotificationFailure
  | MarkAsRead
  | MarkAsReadSuccess
  | MarkAsReadFailure
  | GetProductType
  | GetProductTypeSuccess
  | GetProductTypeFailure
  | UpdateFilterProduct
  | createProduct
  | createProductSuccess
  | createProductFailure
  | GetProduct
  | GetProductSuccess
  | GetProductFailure
  | updateProduct
  | updateProductSuccess
  | updateProductFailure
  | updateActivePackage
  | updateActivePackageSuccess
  | updateActivePackageFailure
  | DeleteProduct
  | DeleteProductSuccess
  | DeleteProductFailure
  | updateStatusProduct
  | updateStatusProductSuccess
  | updateStatusProductFailure
  | GetProductUpgradeDiscountType
  | GetProductUpgradeDiscountTypeSuccess
  | GetProductUpgradeDiscountTypeFailure
  | createProductUpgradeDiscountType
  | createProductUpgradeDiscountTypeSuccess
  | createProductUpgradeDiscountTypeFailure
  | updateStatusProductUpgradeDiscountType
  | updateStatusProductUpgradeDiscountTypeSuccess
  | updateStatusProductUpgradeDiscountTypeFailure
  | DeleteProductUpgradeDiscountType
  | DeleteProductUpgradeDiscountTypeSuccess
  | DeleteProductUpgradeDiscountTypeFailure
  | GetProductUpgradeDiscountTypeDetail
  | GetProductUpgradeDiscountTypeDetailSuccess
  | GetProductUpgradeDiscountTypeDetailFailure
  | updateProductUpgradeDiscountType
  | updateProductUpgradeDiscountTypeSuccess
  | updateProductUpgradeDiscountTypeFailure
  | UpdateFilterProductUpgradeDiscount
  | createCompanyMapping
  | createCompanyMappingSuccess
  | createCompanyMappingFailure
  | updateCompanyMapping
  | updateCompanyMappingSuccess
  | updateCompanyMappingFailure
  | deleteCompanyMapping
  | deleteCompanyMappingSuccess
  | deleteCompanyMappingFailure
  | getCompanyMapping
  | getCompanyMappingSuccess
  | getCompanyMappingFailure;
