export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';

export type permissionType = {
  data: any;
};

export interface GetPermission {
  type: typeof GET_PERMISSION;
}

export interface GetPermissionSuccess {
  type: typeof GET_PERMISSION_SUCCESS;
  payload: permissionType;
}

export interface GetPermissionFailure {
  type: typeof GET_PERMISSION_FAILURE;
}

export type PermissionDispatchTypes = GetPermission | GetPermissionSuccess | GetPermissionFailure;
