import ApiClient from '@utils/api-client';
import { ApiEndpoint } from '@utils/constant/api-enpoint.constant';

const { settings } = ApiEndpoint;
const {
  product: {
    dropdown: { packageCustomerCategory },
  },
  customer: { category },
} = settings;

const getResponsibleAgent = payload =>
  ApiClient.withAuth().then(api => api.get('/settings/region/responsible-agent', payload, {}).then(result => result?.data));
const getResponsibleAgentAreaService = (statusType: number, locationId: number | null | undefined) =>
  ApiClient.withAuth().then(api =>
    api.get(`/settings/region/${statusType}/type/${locationId}/responsible-agent-area`, {}, {}).then(result => result?.data),
  );
const getIsLocationMappedService = (payload: { locationId: number | null | undefined; accessType: number }) =>
  ApiClient.withAuth().then(api =>
    api
      .get(
        `/settings/location-region-area/${payload.locationId}/is-location-mapped`,
        {
          location_id: payload.locationId,
          whos_access: payload.accessType,
        },
        {},
      )
      .then(result => result?.data),
  );

const getResponsibleAgentRegionService = (payload: { agentId: number }) =>
  ApiClient.withAuth().then(api =>
    api.get(`/settings/region/region-area/${payload.agentId}/responsible-agent`, {}, {}).then(result => result?.data),
  );

const getNotificationsService = () =>
  ApiClient.withAuth().then(api => api.get(`/settings/notification-user/?page=1&limit=999`, {}, {}).then(result => result?.data));

const actionMarkAsDoneService = payload =>
  ApiClient.withAuth().then(api => api.put(`/settings/notification-user/mark-as-read`, payload, {}).then(result => result?.data));

const actionMarkAllAsDoneService = () =>
  ApiClient.withAuth().then(api => api.put(`/settings/notification-user/mark-all-as-read`, {}, {}).then(result => result?.data));

const getProductService = (payload: any) =>
  ApiClient.withAuth().then(api => api.get(`leads/products/packages`, payload, {}).then(result => result?.data));
const getProductUpgradeService = (payload: any) =>
  ApiClient.withAuth().then(api =>
    api.get(`/settings/upgrade-package-discount/group-by-package`, payload, {}).then(result => result?.data),
  );

const getProductCategoryService = () =>
  ApiClient.withAuth().then(api => api.get(`leads/products/category-id`, {}, {}).then(result => result?.data));

const getProductSubCategoryService = (id: number) =>
  ApiClient.withAuth().then(api => api.get(`leads/products/sub-category/${id}`, {}, {}).then(result => result?.data));

const getPurchaseTypeService = () =>
  ApiClient.withAuth().then(api => api.get(`leads/products/purchase-type`, {}, {}).then(result => result?.data));

const createProductService = payload =>
  ApiClient.withAuth()
    .then(api => api.post(`/leads/products/packages`, payload, null))
    .then(result => result?.data);
const createProductUpgradeService = payload =>
  ApiClient.withAuth()
    .then(api => api.post(`/settings/upgrade-package-discount/bulk-save`, payload, null))
    .then(result => result?.data);
const getProductDetailService = id =>
  ApiClient.withAuth().then(api => api.get(`/leads/products/${id}`, null, null).then(result => result?.data));
const updateProductService = (id: any, payload: any) =>
  ApiClient.withAuth().then(api => api.put(`/leads/products/packages/${id}`, payload, null).then(result => result?.data));
const deleteProductService = (id: number) =>
  ApiClient.withAuth()
    .then(api => api.delete(`/leads/products/packages/${id}`, null))
    .then(result => result?.data);
const deleteProductUpgradeService = (payload: any) =>
  ApiClient.withAuth()
    .then(api => api.deleteBulk(`/settings/upgrade-package-discount/group-by-package`, payload, null))
    .then(result => result?.data);

const getProductDurationService = () =>
  ApiClient.withAuth().then(api => api.get(`leads/products/durations`, {}, {}).then(result => result?.data));
const updateStatusProductService = (id: number) =>
  ApiClient.withAuth().then(api => api.put(`/leads/products/packages/${id}/active-inactive`, id, null).then(result => result?.data));

const getUpgradeDiscountTypeService = payload =>
  ApiClient.withAuth().then(api => api.get(`/settings/upgrade-package-discount-type`, payload, {}).then(result => result?.data));
const createUpgradeDiscountTypeService = payload =>
  ApiClient.withAuth()
    .then(api => api.post(`/settings/upgrade-package-discount-type/`, payload, null))
    .then(result => result?.data);
const updateStatusDiscountTypeService = (id: number) =>
  ApiClient.withAuth().then(api =>
    api.put(`/settings/upgrade-package-discount-type/${id}/active-inactive`, id, null).then(result => result?.data),
  );
const deleteProductUpgradeDiscountTypeService = (id: number) =>
  ApiClient.withAuth()
    .then(api => api.delete(`/settings/upgrade-package-discount-type/${id}`, null))
    .then(result => result?.data);
const getProducUpgradeDiscountTypetDetailService = id =>
  ApiClient.withAuth().then(api => api.get(`/settings/upgrade-package-discount-type/${id}`, null, null).then(result => result?.data));
const updateProductUpgradeDiscountTypeService = (id: any, payload: any) =>
  ApiClient.withAuth().then(api => api.put(`/settings/upgrade-package-discount-type/${id}`, payload, null).then(result => result?.data));
const getProductUpgradeDetailService = payload =>
  ApiClient.withAuth().then(api => api.get(`/settings/upgrade-package-discount/`, payload, null).then(result => result?.data));
const updateProductUpgradeService = (payload: any) =>
  ApiClient.withAuth().then(api => api.put(`/settings/upgrade-package-discount/bulk-update`, payload, null).then(result => result?.data));
const updateStatusProductUpgradeService = (payload: any) =>
  ApiClient.withAuth().then(api =>
    api.put(`/settings/upgrade-package-discount/group-by-package/active-inactive`, payload, null).then(result => result?.data),
  );

const updateActivePackageService = (leadId, payload: any) =>
  ApiClient.withAuth().then(api =>
    api.put(`/leads/transaction/${leadId}/update-active-package`, payload, null).then(result => result?.data),
  );
const getLeadHomeowner = () =>
  ApiClient.withAuth().then(api => api.get(`/settings/region/home-owner-responsible-agent`, {}, null).then(result => result?.data));
const createCompanyMappingService = payload =>
  ApiClient.withAuth()
    .then(api => api.post(`/settings/company-region-area/`, payload, null))
    .then(result => result?.data);
const updateCompanyMappingService = (id: any, payload: any) =>
  ApiClient.withAuth().then(api => api.put(`/settings/company-region-area/${id}`, payload, null).then(result => result?.data));
const deleteCompanyMappingService = (id: number) =>
  ApiClient.withAuth()
    .then(api => api.delete(`/settings/company-region-area/${id}`, null))
    .then(result => result?.data);
const getCompanyMappingService = (payload: any) =>
  ApiClient.withAuth().then(api => api.get(`/settings/company-region-area/`, payload, null).then(result => result?.data));

const getProductCustomerCategoryService = () =>
  ApiClient.withAuth().then(api => api.get(packageCustomerCategory, {}, {}).then(result => result?.data));

const getCustomerCategoryService = () => ApiClient.withAuth().then(api => api.get(category, {}, {}).then(result => result?.data));

export {
  getResponsibleAgent,
  getResponsibleAgentAreaService,
  getIsLocationMappedService,
  getResponsibleAgentRegionService,
  getNotificationsService,
  actionMarkAsDoneService,
  actionMarkAllAsDoneService,
  getProductService,
  getProductCategoryService,
  getProductSubCategoryService,
  getPurchaseTypeService,
  createProductService,
  getProductDetailService,
  updateProductService,
  deleteProductService,
  getProductDurationService,
  updateStatusProductService,
  getProductUpgradeService,
  getUpgradeDiscountTypeService,
  createUpgradeDiscountTypeService,
  updateStatusDiscountTypeService,
  deleteProductUpgradeDiscountTypeService,
  getProducUpgradeDiscountTypetDetailService,
  updateProductUpgradeDiscountTypeService,
  createProductUpgradeService,
  deleteProductUpgradeService,
  getProductUpgradeDetailService,
  updateProductUpgradeService,
  updateStatusProductUpgradeService,
  updateActivePackageService,
  getLeadHomeowner,
  createCompanyMappingService,
  updateCompanyMappingService,
  deleteCompanyMappingService,
  getCompanyMappingService,
  getProductCustomerCategoryService,
  getCustomerCategoryService
};
