import { Dropdown, Layout, Avatar, Menu, Divider, Tooltip, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { userLogout } from '@redux/auth/auth-action';
import { useLocation, useHistory } from 'react-router-dom';
import '@assets/css/layout.scss';
import { useEffect, useState } from 'react';
import NotificationDrawer from '@components/notification/notification';
import { getNotifications } from '@redux/settings/setting-action';

const { Header } = Layout;
const { Item } = Menu;

const HeaderApp = ({ handleToggle, isCollapse }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: any) => state.auth);
  const notificationData = useSelector((state: any) => state.settings);
  const { notificationTotal, notification, successGetNotification } = notificationData;
  const location = useLocation();

  const [unreadNotification, setUnreadNotification] = useState(0);

  const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] = useState(false);

  const onChangeLogout = () => {
    dispatch(userLogout());
    history.push('/auth/login');
  };

  const pageName = () => {
    const page_name = location.pathname.substring(1).split('/')[0];
    let title_name = '';
    page_name.split('-').map(name => {
      title_name += name.charAt(0).toUpperCase() + name.slice(1) + ' ';
    });
    return title_name;
  };

  useEffect(() => {
    // if (loadingGetNotification && notificationTotal.totalFilteredData == notification.length) {
    //   reloadNotifications()
    // }
    setUnreadNotification(notificationTotal.total_notification_unread);
  }, [notification, notificationTotal, successGetNotification]);

  const reloadNotifications = async () => {
    await dispatch(getNotifications());
  };

  return (
    <Header className='siteLayoutBackground site-layout-sub-header-background nav-header'>
      <div className='nav-header__left'>
        <Tooltip title={isCollapse ? 'Toggle Bigger Menu' : 'Toggle Small Menu'} placement='bottomLeft'>
          <img
            className='expand-icon'
            alt='expand'
            src='https://s3-ap-southeast-1.amazonaws.com/crm-internaltools-staging.99iddev.net/asset-fe/img/icon/Expand.svg'
            onClick={handleToggle}
          />
        </Tooltip>

        <Title level={4} className='menu-title'>
          {pageName()}
        </Title>
        <div id='searchBar'></div>
      </div>
      <div className='profile-container nav-header__right'>
        <Badge count={unreadNotification > 0 ? unreadNotification : 0}>
          <BellOutlined
            style={{ fontSize: '26px', cursor: 'pointer' }}
            onClick={() => {
              reloadNotifications();
              setIsNotificationDrawerVisible(true);
            }}
          />
        </Badge>
        <div className='divider' />
        <Dropdown
          overlay={
            <Menu style={{ width: '100%', minWidth: '200px', borderRadius: '8px' }} className='dropdown-profile'>
              <Item>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span>
                    <Avatar
                      style={{ backgroundColor: '#87d068', marginRight: '10px', marginTop: '5px' }}
                      icon={<UserOutlined style={{ fontSize: '16px' }} />}
                    />
                  </span>
                  <span>
                    <div>{auth.user?.user_info?.fullname || '...'}</div>
                    <div style={{ fontSize: '12px', color: '#c0c0c0' }}>{auth.user?.user_info?.email || '...'}</div>
                  </span>
                </div>
              </Item>
              <Item>
                <Divider />
              </Item>

              <Item>
                <span>Edit Profile</span>
              </Item>
              <Item>
                <span>Change Password </span>
              </Item>
              <Item>
                <Divider />
              </Item>
              <Item onClick={onChangeLogout}>
                <span>Logout</span>
              </Item>
            </Menu>
          }
        >
          <span>
            <Avatar
              style={{ backgroundColor: '#87d068', marginRight: '5px', width: '50px', height: '50px' }}
              icon={<UserOutlined style={{ fontSize: '30px', marginTop: '8px' }} />}
            />{' '}
            {auth.user?.user_info?.fullname || '...'}
          </span>
        </Dropdown>
      </div>
      <NotificationDrawer
        visible={isNotificationDrawerVisible}
        onClose={() => {
          setIsNotificationDrawerVisible(false);
        }}
        reloadNotifications={reloadNotifications}
      />
    </Header>
  );
};

export default HeaderApp;
