import ReactDOM from 'react-dom';
import './index.css';
import BaseApp from './BaseApp';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import { rootReducer } from './redux/rootReducer';

ReactDOM.render(
  <React.StrictMode>
    <BaseApp store={rootReducer} />
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
