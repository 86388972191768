import {
  APPROVAL_DISCOUNT,
  APPROVAL_DISCOUNT_SUCCESS,
  APPROVAL_DISCOUNT_FAILURE,
  ConfirmDispatchTypes,
  discountType,
  DECLINE_NOTES,
  DECLINE_NOTES_SUCCESS,
  DECLINE_NOTES_FAILURE,
  declineType,
} from './confirmation-type';

interface DefaultStateI {
  loading: boolean;
  discount?: discountType;
  decline?: declineType;
}

const defaultState: DefaultStateI = {
  loading: false,
};

const ConfirmMenuReducer = (state: DefaultStateI = defaultState, action: ConfirmDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case APPROVAL_DISCOUNT:
      return {
        loading: true,
      };
    case APPROVAL_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: action.payload,
      };
    case APPROVAL_DISCOUNT_FAILURE:
      return {
        loading: false,
      };

    case DECLINE_NOTES:
      return {
        loading: true,
      };
    case DECLINE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        decline: action.payload,
      };
    case DECLINE_NOTES_FAILURE:
      return {
        loading: false,
      };

    default:
      return state;
  }
};

export default ConfirmMenuReducer;
