import {
  GET_RESPONSIBLE_AGENT_AREA,
  GET_RESPONSIBLE_AGENT_AREA_SUCCESS,
  GET_RESPONSIBLE_AGENT_AREA_FAILURE,
  GET_RESPONSIBLE_AGENT,
  GET_RESPONSIBLE_AGENT_SUCCESS,
  GET_RESPONSIBLE_AGENT_FAILURE,
  SettingDispatchTypes,
  GET_IS_LOCATION_MAPPED,
  GET_IS_LOCATION_MAPPED_SUCCESS,
  GET_IS_LOCATION_MAPPED_FAILURE,
  GET_RESPONSIBLE_AGENT_REGION,
  GET_RESPONSIBLE_AGENT_REGION_SUCCESS,
  GET_RESPONSIBLE_AGENT_REGION_FAILURE,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_FAILURE,
  productSettings,
  UPDATE_PRODUCT_SETTING_FILTER,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  UPDATE_STATUS_PRODUCT,
  UPDATE_STATUS_PRODUCT_SUCCESS,
  UPDATE_STATUS_PRODUCT_FAILURE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS,
  GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE,
  UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER,
  UPDATE_ACTIVE_PACKAGE,
  UPDATE_ACTIVE_PACKAGE_SUCCESS,
  UPDATE_ACTIVE_PACKAGE_FAILURE,
} from './setting-types';

interface DefaultStateI {
  loadingGetResponsibleAgentArea: boolean;
  responsibleAgentArea: Array<{
    id: number;
    fullname: string;
  }>;
  errorGetResponsibleAgentArea: boolean;
  successGetResponsibleAgentArea: boolean;
  loadingGetResponsibleAgent: boolean;
  responsibleAgent: Array<{
    id: number;
    fullname: string;
    created_at: string;
    updated_at: string;
  }>;
  errorGetResponsibleAgent: boolean;
  successGetResponsibleAgent: boolean;
  loadingGetIsLocationMapped: boolean;
  successGetIsLocationMapped: boolean;
  errorGetIsLocationMapped: boolean;
  isLocationMapped: any;
  isLocationMappedMessage: string;
  loadingGetResponsibleAgentRegion: boolean;
  successGetResponsibleAgentRegion: boolean;
  errorGetResponsibleAgentRegion: boolean;
  responsibleAgentRegion: any;
  responsibleAgentRegionMessage: string;

  loadingGetNotification: boolean;
  errorGetNotification: boolean;
  successGetNotification: boolean;
  notification: any;
  notificationTotal: any;
  loadingGetProduct: boolean;
  errorGetProduct: boolean;
  successGetProduct: boolean;
  product?: productSettings;
  filterQuery: any;

  loadingCreateProduct: boolean;
  successCreateProduct: boolean;
  errorCreateProduct: boolean;
  errorMessage: any;
  loadingGetProductDetail: boolean;
  successGetProductDetail: boolean;
  errorGetProductDetail: boolean;
  productDetail: any;

  loadingUpdateProduct: boolean;
  successUpdateProduct: boolean;
  errorUpdateProduct: boolean;
  messageUpdateProduct: any;

  loadingUpdateActivePackage: boolean;
  successUpdateActivePackage: boolean;
  errorUpdateActivePackage: boolean;
  messageUpdateActivePackage: any;

  loadingDeleteProduct: boolean;
  successDeleteProduct: boolean;
  errorDeleteProduct: boolean;
  messageDeleteProduct: any;
  errorMessageDeleteProduct: any;

  loadingGetProductUpgradeDiscountType: boolean;
  errorGetProductUpgradeDiscountType: boolean;
  successGetProductUpgradeDiscountType: boolean;
  upgradeDiscountType?: {
    data: any;
  };
  loadingCreateProductUpgradeDiscountType: boolean;
  successCreateProductUpgradeDiscountType: boolean;
  errorCreateProductUpgradeDiscountType: boolean;
  errorMessageDiscountType: any;
  loadingGetProductUpgradeDiscountTypeDetail: boolean;
  successGetProductUpgradeDiscountTypeDetail: boolean;
  errorGetProductUpgradeDiscountTypeDetail: boolean;
  productUpgradeDiscountTypeDetail: any;
  loadingDeleteProductUpgradeDiscountType: boolean;
  successDeleteProductUpgradeDiscountType: boolean;
  errorDeleteProductUpgradeDiscountType: boolean;
  messageDeleteProductUpgradeDiscountType: any;
  errorMessageDeleteProductUpgradeDiscountType: any;
  loadingUpdateProductUpgradeDiscountType: boolean;
  successUpdateProductUpgradeDiscountType: boolean;
  errorUpdateProductUpgradeDiscountType: boolean;
  messageUpdateProductUpgradeDiscountType: any;

  filterUpgradeDiscountQuery: any;
}

const defaultState: DefaultStateI = {
  loadingGetResponsibleAgentArea: false,
  errorGetResponsibleAgentArea: false,
  successGetResponsibleAgentArea: false,
  responsibleAgentArea: [],
  loadingGetResponsibleAgent: false,
  errorGetResponsibleAgent: false,
  successGetResponsibleAgent: false,
  responsibleAgent: [],
  loadingGetIsLocationMapped: false,
  successGetIsLocationMapped: false,
  errorGetIsLocationMapped: false,
  isLocationMapped: false,
  isLocationMappedMessage: '',
  loadingGetResponsibleAgentRegion: false,
  successGetResponsibleAgentRegion: false,
  errorGetResponsibleAgentRegion: false,
  responsibleAgentRegion: [],
  responsibleAgentRegionMessage: '',
  loadingGetNotification: false,
  errorGetNotification: false,
  successGetNotification: false,
  notification: [],
  notificationTotal: [],
  loadingGetProduct: false,
  errorGetProduct: false,
  successGetProduct: false,
  product: {
    data: [],
  },
  filterQuery: {},

  loadingCreateProduct: false,
  successCreateProduct: false,
  errorCreateProduct: false,
  errorMessage: {},
  loadingGetProductDetail: false,
  successGetProductDetail: false,
  errorGetProductDetail: false,
  productDetail: {},

  loadingUpdateProduct: false,
  successUpdateProduct: false,
  errorUpdateProduct: false,
  messageUpdateProduct: {},

  loadingUpdateActivePackage: false,
  successUpdateActivePackage: false,
  errorUpdateActivePackage: false,
  messageUpdateActivePackage: {},

  loadingDeleteProduct: false,
  successDeleteProduct: false,
  errorDeleteProduct: false,
  messageDeleteProduct: {},
  errorMessageDeleteProduct: {},

  loadingGetProductUpgradeDiscountType: false,
  errorGetProductUpgradeDiscountType: false,
  successGetProductUpgradeDiscountType: false,
  upgradeDiscountType: {
    data: [],
  },
  loadingCreateProductUpgradeDiscountType: false,
  successCreateProductUpgradeDiscountType: false,
  errorCreateProductUpgradeDiscountType: false,
  errorMessageDiscountType: {},
  loadingGetProductUpgradeDiscountTypeDetail: false,
  successGetProductUpgradeDiscountTypeDetail: false,
  errorGetProductUpgradeDiscountTypeDetail: false,
  productUpgradeDiscountTypeDetail: {},
  loadingDeleteProductUpgradeDiscountType: false,
  successDeleteProductUpgradeDiscountType: false,
  errorDeleteProductUpgradeDiscountType: false,
  messageDeleteProductUpgradeDiscountType: {},
  errorMessageDeleteProductUpgradeDiscountType: {},
  loadingUpdateProductUpgradeDiscountType: false,
  successUpdateProductUpgradeDiscountType: false,
  errorUpdateProductUpgradeDiscountType: false,
  messageUpdateProductUpgradeDiscountType: {},
  filterUpgradeDiscountQuery: {},
};

const settingReducer = (state: DefaultStateI = defaultState, action: SettingDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_RESPONSIBLE_AGENT_AREA:
      return {
        ...state,
        loadingGetResponsibleAgentArea: true,
        errorGetResponsibleAgentArea: false,
        successGetResponsibleAgentArea: false,
      };
    case GET_RESPONSIBLE_AGENT_AREA_SUCCESS:
      return {
        ...state,
        loadingGetResponsibleAgentArea: false,
        errorGetResponsibleAgentArea: false,
        successGetResponsibleAgentArea: true,
        responsibleAgentArea: action.payload,
      };
    case GET_RESPONSIBLE_AGENT_AREA_FAILURE:
      return {
        ...state,
        loadingGetResponsibleAgentArea: false,
        errorGetResponsibleAgentArea: true,
        successGetResponsibleAgentArea: false,
      };
    case GET_RESPONSIBLE_AGENT:
      return {
        ...state,
        loadingGetResponsibleAgent: true,
        errorGetResponsibleAgent: false,
        successGetResponsibleAgent: false,
      };
    case GET_RESPONSIBLE_AGENT_SUCCESS:
      return {
        ...state,
        loadingGetResponsibleAgent: false,
        errorGetResponsibleAgent: false,
        successGetResponsibleAgent: true,
        responsibleAgent: action.payload,
      };
    case GET_RESPONSIBLE_AGENT_FAILURE:
      return {
        ...state,
        loadingGetResponsibleAgent: false,
        errorGetResponsibleAgent: true,
        successGetResponsibleAgent: false,
      };
    case GET_IS_LOCATION_MAPPED:
      return {
        ...state,
        loadingGetIsLocationMapped: true,
        successGetIsLocationMapped: false,
        errorGetIsLocationMapped: false,
        isLocationMappedMessage: '',
      };
    case GET_IS_LOCATION_MAPPED_SUCCESS:
      return {
        ...state,
        loadingGetIsLocationMapped: false,
        successGetIsLocationMapped: true,
        errorGetIsLocationMapped: false,
        isLocationMapped: action.payload,
        isLocationMappedMessage: action.message,
      };
    case GET_IS_LOCATION_MAPPED_FAILURE:
      return {
        ...state,
        loadingGetIsLocationMapped: false,
        successGetIsLocationMapped: false,
        errorGetIsLocationMapped: true,
        isLocationMappedMessage: action.message,
      };
    case GET_RESPONSIBLE_AGENT_REGION:
      return {
        ...state,
        loadingGetResponsibleAgentRegion: true,
        successGetResponsibleAgentRegion: false,
        errorGetResponsibleAgentRegion: false,
        responsibleAgentRegionMessage: '',
      };
    case GET_RESPONSIBLE_AGENT_REGION_SUCCESS:
      return {
        ...state,
        loadingGetResponsibleAgentRegion: false,
        successGetResponsibleAgentRegion: true,
        errorGetResponsibleAgentRegion: false,
        responsibleAgentRegion: action.payload,
        responsibleAgentRegionMessage: action.message,
      };
    case GET_RESPONSIBLE_AGENT_REGION_FAILURE:
      return {
        ...state,
        loadingGetResponsibleAgentRegion: false,
        successGetResponsibleAgentRegion: false,
        errorGetResponsibleAgentRegion: true,
        responsibleAgentRegionMessage: action.message,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        loadingGetNotification: true,
        errorGetNotification: false,
        successGetNotification: false,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: false,
        successGetNotification: true,
        notification: action.payload,
        notificationTotal: action.total,
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: true,
        successGetNotification: false,
      };
    case MARK_AS_READ:
      return {
        ...state,
        loadingGetNotification: true,
        errorGetNotification: false,
        successGetNotification: false,
      };
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: false,
        successGetNotification: true,
      };
    case MARK_AS_READ_FAILURE:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: true,
        successGetNotification: false,
      };
    case GET_PRODUCT_CATEGORY:
      return {
        ...state,
        loadingGetProduct: true,
        errorGetProduct: false,
        successGetProduct: false,
      };
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingGetProduct: false,
        errorGetProduct: false,
        successGetProduct: true,
        product: action.payload,
      };
    case GET_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        loadingGetProduct: false,
        errorGetProduct: true,
        successGetProduct: false,
      };
    case UPDATE_PRODUCT_SETTING_FILTER:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        loadingCreateProduct: true,
        successCreateProduct: false,
        errorCreateProduct: false,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingCreateProduct: false,
        successCreateProduct: true,
        errorCreateProduct: false,
        productDetail: action.payload,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingCreateProduct: false,
        successCreateProduct: false,
        errorCreateProduct: true,
        errorMessage: action.message,
      };
    case GET_PRODUCT:
      return {
        ...state,
        loadingGetProductDetail: true,
        successGetProductDetail: false,
        errorGetProductDetail: false,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingGetProductDetail: false,
        successGetProductDetail: true,
        errorGetProductDetail: false,
        productDetail: action.payload,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loadingGetProductDetail: false,
        successGetProductDetail: false,
        errorGetProductDetail: true,
        errorMessage: action.message,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        loadingUpdateProduct: true,
        successUpdateProduct: false,
        errorUpdateProduct: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: true,
        errorUpdateProduct: false,
        product: action.payload,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errorUpdateProduct: true,
        errorMessage: action.message,
      };
    case UPDATE_ACTIVE_PACKAGE:
      return {
        ...state,
        loadingUpdateProduct: true,
        successUpdateProduct: false,
        errorUpdateProduct: false,
      };
    case UPDATE_ACTIVE_PACKAGE_SUCCESS:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: true,
        errorUpdateProduct: false,
        product: action.payload,
      };
    case UPDATE_ACTIVE_PACKAGE_FAILURE:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errorUpdateProduct: true,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        loadingDeleteProduct: true,
        successDeleteProduct: false,
        errorDeleteProduct: false,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingDeleteProduct: false,
        successDeleteProduct: true,
        errorDeleteProduct: false,
        messageDeleteProduct: action.message,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingDeleteProduct: false,
        successDeleteProduct: false,
        errorDeleteProduct: true,
        errorMessageDeleteProduct: action.message,
      };
    case UPDATE_STATUS_PRODUCT:
      return {
        ...state,
        loadingUpdateProduct: true,
        successUpdateProduct: false,
        errorUpdateProduct: false,
      };
    case UPDATE_STATUS_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: true,
        errorUpdateProduct: false,
        messageUpdateProduct: action.message,
      };
    case UPDATE_STATUS_PRODUCT_FAILURE:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errorUpdateProduct: true,
        messageUpdateProduct: action.message,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE:
      return {
        ...state,
        loadingGetProductUpgradeDiscountType: true,
        errorGetProductUpgradeDiscountType: false,
        successGetProductUpgradeDiscountType: false,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loadingGetProductUpgradeDiscountType: false,
        errorGetProductUpgradeDiscountType: false,
        successGetProductUpgradeDiscountType: true,
        upgradeDiscountType: action.payload,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE:
      return {
        ...state,
        loadingGetProductUpgradeDiscountType: false,
        errorGetProductUpgradeDiscountType: true,
        successGetProductUpgradeDiscountType: false,
      };
    case CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE:
      return {
        ...state,
        loadingCreateProduct: true,
        successCreateProduct: false,
        errorCreateProduct: false,
      };
    case CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loadingCreateProduct: false,
        successCreateProduct: true,
        errorCreateProduct: false,
        productUpgradeDiscountTypeDetail: action.payload,
      };
    case CREATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE:
      return {
        ...state,
        loadingCreateProduct: false,
        successCreateProduct: false,
        errorCreateProduct: true,
        errorMessage: action.message,
      };
    case UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE:
      return {
        ...state,
        loadingUpdateProduct: true,
        successUpdateProduct: false,
        errorUpdateProduct: false,
      };
    case UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: true,
        errorUpdateProduct: false,
        messageUpdateProduct: action.message,
      };
    case UPDATE_STATUS_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errorUpdateProduct: true,
        messageUpdateProduct: action.message,
      };
    case DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE:
      return {
        ...state,
        loadingDeleteProductUpgradeDiscountType: true,
        successDeleteProductUpgradeDiscountType: false,
        errorDeleteProductUpgradeDiscountType: false,
      };
    case DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loadingDeleteProductUpgradeDiscountType: false,
        successDeleteProductUpgradeDiscountType: true,
        errorDeleteProductUpgradeDiscountType: false,
        messageDeleteProductUpgradeDiscountType: action.message,
      };
    case DELETE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE:
      return {
        ...state,
        loadingDeleteProductUpgradeDiscountType: false,
        successDeleteProductUpgradeDiscountType: false,
        errorDeleteProductUpgradeDiscountType: true,
        errorMessageDeleteProductUpgradeDiscountType: action.message,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL:
      return {
        ...state,
        loadingGetProductUpgradeDiscountTypeDetail: true,
        successGetProductUpgradeDiscountTypeDetail: false,
        errorGetProductUpgradeDiscountTypeDetail: false,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        loadingGetProductUpgradeDiscountTypeDetail: false,
        successGetProductUpgradeDiscountTypeDetail: true,
        errorGetProductUpgradeDiscountTypeDetail: false,
        productUpgradeDiscountTypeDetail: action.payload,
      };
    case GET_PRODUCT_UPGRADE_DISCOUNT_TYPE_DETAIL_FAILURE:
      return {
        ...state,
        loadingGetProductUpgradeDiscountTypeDetail: false,
        successGetProductUpgradeDiscountTypeDetail: false,
        errorGetProductUpgradeDiscountTypeDetail: true,
        errorMessage: action.message,
      };
    case UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE:
      return {
        ...state,
        loadingUpdateProductUpgradeDiscountType: true,
        successUpdateProductUpgradeDiscountType: false,
        errorUpdateProductUpgradeDiscountType: false,
      };
    case UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loadingUpdateProductUpgradeDiscountType: false,
        successUpdateProductUpgradeDiscountType: true,
        errorUpdateProductUpgradeDiscountType: false,
        product: action.payload,
      };
    case UPDATE_PRODUCT_UPGRADE_DISCOUNT_TYPE_FAILURE:
      return {
        ...state,
        loadingUpdateProductUpgradeDiscountType: false,
        successUpdateProductUpgradeDiscountType: false,
        errorUpdateProductUpgradeDiscountType: true,
        errorMessage: action.message,
      };
    case UPDATE_PRODUCT_UPGRADE_DISCOUNT_SETTING_FILTER:
      return {
        ...state,
        filterUpgradeDiscountQuery: action.payload,
      };
  }

  return state;
};

export default settingReducer;
