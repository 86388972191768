import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '@utils/storage';

const RoutePrivate = ({ component: Component, permissions, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => (isLoggedIn() ? <Component {...props} permissions={permissions} /> : <Redirect to='/auth/login' />)}
    />
  );
};

export default RoutePrivate;
