import './App.scss';
import '@assets/css/ant.less';
import '@assets/css/loading.scss';
import '@assets/fonts/DMSans-Regular.ttf';

import LazyDelay from '@utils/lazy-delay';
import LayoutApp from '@components/layout/layout-app';
import RouteChildren from '@components/route/route-children';
import { AppRoutes } from '@routes/routes';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetPermissions } from '@redux/app/permission/permission-action';
import { getNotifications } from '@redux/settings/setting-action';
const Error404 = LazyDelay(import('./error/404'));
/**
 *
 * @param  {any} { fallback }
 * @return
 * @author Adam Lesmana G.S <aelgees.dev@gmail.com>
 * @author Fajar SJ <fajarthea5@gmail.com>
 * @author thomasnkurniawan <thomas.kurniawan@99.co>
 * @author albirro <albirrofirdaus@gmail.com>
 */
function App({ fallback }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const routes = AppRoutes;
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    syncPermissions();
    syncNotifications();
    // eslint-disable-next-line
  }, []);

  const syncPermissions = async () => {
    setLoading(true);
    const userPermission: any = await dispatch(GetPermissions());
    if (userPermission) {
      routes.push({ path: '*', name: 'Error404', component: Error404 });
      setPermissions(userPermission);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const syncNotifications = async () => {
    await dispatch(getNotifications());
  };

  return (
    <LayoutApp loading={loading} syncNotifications={syncNotifications}>
      <RouteChildren routes={routes} fallback={fallback} permissions={permissions} />
    </LayoutApp>
  );
}

export default App;
