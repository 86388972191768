import {
  GET_AGENCY,
  GET_AGENCY_FAILURE,
  GET_AGENCY_SUCCESS,
  AgencyDispatchTypes,
  GET_AGENCY_BY_ID,
  GET_AGENCY_SUCCESS_BY_ID,
  GET_AGENCY_FAILURE_BY_ID,
  GET_AGENCY_HEAD,
  GET_AGENCY_HEAD_SUCCESS,
  GET_AGENCY_HEAD_FAILURE,
} from './agency-types';

interface DefaultStateI {
  loadingGetAgency: boolean;
  successGetAgency: boolean;
  errorGetAgency: boolean;
  agency?: any;
}

const defaultState: DefaultStateI = {
  loadingGetAgency: false,
  successGetAgency: false,
  errorGetAgency: false,
  agency: {},
};

const agenciesReducer = (state: DefaultStateI = defaultState, action: AgencyDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_AGENCY:
      return {
        ...state,
        loadingGetAgency: true,
        successGetAgency: false,
        errorGetAgency: false,
      };
    case GET_AGENCY_SUCCESS:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: true,
        errorGetAgency: false,
        agency: action.payload,
      };
    case GET_AGENCY_FAILURE:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: false,
        errorGetAgency: true,
      };

    case GET_AGENCY_BY_ID:
      return {
        ...state,
        loadingGetAgency: true,
        successGetAgency: false,
        errorGetAgency: false,
      };
    case GET_AGENCY_SUCCESS_BY_ID:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: true,
        errorGetAgency: false,
        agency: action.payload,
      };
    case GET_AGENCY_FAILURE_BY_ID:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: false,
        errorGetAgency: true,
      };
    case GET_AGENCY_HEAD:
      return {
        ...state,
        loadingGetAgency: true,
        successGetAgency: false,
        errorGetAgency: false,
      };
    case GET_AGENCY_HEAD_SUCCESS:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: true,
        errorGetAgency: false,
        agency: action.payload,
      };
    case GET_AGENCY_HEAD_FAILURE:
      return {
        ...state,
        loadingGetAgency: false,
        successGetAgency: false,
        errorGetAgency: true,
      };
    default:
      return state;
  }
};

export default agenciesReducer;
