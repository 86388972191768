import { BulkMappingData } from '@pages/settings/company-mapping/bulk-mapping/interface/bulkMapping.interface';

export const BulkCompanyMapper: (data: BulkMappingData[]) => BulkMappingData[] = (data: BulkMappingData[]) => {
  let result: BulkMappingData[];
  result = data.map(x => {
    return {
      ...x,
      search: `${x.file_name ? x.file_name : ''}${x.agency_id ? x.agency_id : ''}${x.sales_id ? x.sales_id : ''}`,
    };
  });
  return result;
};
