export type RequestState = 'pending' | 'fulfilled' | 'rejected';

export enum EnumRequestState {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface RequestTypeInterface {
  message: any;
  status_code: any;
  data?: any;
  meta?: any;
}

export interface PostFileFormDataBody {
  [key: string]: string | Blob;
}
