import { VAT } from '../config/env';
import { PaginationInterface } from './interface/pagination.interface';

export const autoCompleteOptionTransformer = (data: any) => {
  let transformed = [];

  if (!data) {
    return transformed;
  }

  data.forEach((element: { title: any; data: any }) => {
    const title = element.title;
    const searchData = element.data;

    let options = [];
    searchData.forEach((element: { id: any; name: any }) => {
      options.push({
        key: element.id + '|' + title, // append each 'title' on key for querying purpose
        value: element.name,
        label: element.name,
      });
    });

    transformed.push({
      label: title,
      options: options,
    });
  });

  return transformed;
};

export const getVatValue = () => {
  return Number(VAT);
};

export const capitalizeFirstLetter: (string: string) => string = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export interface GetPaginationInterface {
  current: number;
  totalData: number;
  showInValue: number;
}

export const paginationCalculation: (items: GetPaginationInterface) => GetPaginationInterface = (items: GetPaginationInterface) => {
  const { current, totalData, showInValue } = items;
  const currentPage = Math.max(1, current);
  const pageSize = Math.max(1, showInValue);
  const startIndex = (currentPage - 1) * pageSize;

  return {
    current: startIndex + 1,
    totalData,
    showInValue: pageSize,
  };
};

export const stringMatch: (pattern: string, input: string) => boolean = (pattern: string, input: string) => {
  if (pattern && input) {
    const patternRegex = `%${pattern.toLowerCase()}%`;
    const escapedPattern = patternRegex.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regexPattern = escapedPattern.replace(/%/g, '.*').replace(/_/g, '.');
    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(input);
  } else {
    return false;
  }
};

export const paginateAndSerializeData = <T>(data: T[], pagination: PaginationInterface): any[] => {
  if (!data || !pagination) {
    return [];
  }
  const { current, pageSize } = pagination;

  // Calculate pagination details
  const startIndex = (current - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);

  // Get the subset of data for the current page
  const paginatedData = data.slice(startIndex, endIndex);

  return paginatedData || [];
};
