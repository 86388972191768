import { GET_AREA, GET_AREA_SUCCESS, GET_AREA_FAILURE, AreaDispatchTypes, areaType } from './area-types';

interface DefaultStateI {
  loading: boolean;
  area?: areaType;
}

const defaultState: DefaultStateI = {
  loading: false,
};

const areaReducer = (state: DefaultStateI = defaultState, action: AreaDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_AREA:
      return {
        loading: false,
      };
    case GET_AREA_SUCCESS:
      return {
        ...state,
        loading: true,
        area: action.payload,
      };
    case GET_AREA_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default areaReducer;
