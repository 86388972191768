import {
  GET_LEADS,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAILURE,
  GET_LEADS_SUGGESTION_SUCCESS,
  GET_LEADS_SUGGESTION_FAILURE,
  CREATE_ASSIGN_MAPPING_LOCATION,
  CREATE_ASSIGN_MAPPING_LOCATION_SUCCESS,
  CREATE_ASSIGN_MAPPING_LOCATION_FAILURE,
  UPDATE_ASSIGN_LEAD,
  UPDATE_ASSIGN_LEAD_SUCCESS,
  UPDATE_ASSIGN_LEAD_FAILURE,
  DELETE_LEADS,
  DELETE_LEADS_SUCCESS,
  DELETE_LEADS_FAILURE,
  ARCHIVE_LEADS,
  ARCHIVE_LEADS_SUCCESS,
  ARCHIVE_LEADS_FAILURE,
  FORCE_CORPORATE_DEALS,
  FORCE_CORPORATE_DEALS_SUCCESS,
  FORCE_CORPORATE_DEALS_FAILURE,
  UNARCHIVE_LEADS,
  UNARCHIVE_LEADS_SUCCESS,
  UNARCHIVE_LEADS_FAILURE,
  UPDATE_FILTER_LEADS,
  LeadsDispatchTypes,
  leadsType,
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_FAILURE,
  GET_PURCHASE_TYPE,
  GET_PURCHASE_TYPE_SUCCESS,
  GET_PURCHASE_TYPE_FAILURE,
  BULK_ASSIGNMENT_LEADS,
  BULK_ASSIGNMENT_LEADS_SUCCESS,
  BULK_ASSIGNMENT_LEADS_FAILURE,
  BulkAssingmentLeadsInetrface,
  BULK_ASSIGNMENT_LEADS_RESET,
} from './leads-types';

export interface DefaultStateI {
  loading: boolean;
  leads?: leadsType;
  lead_suggestions?: any;
  loadingCreateAssignMappingLocation: boolean;
  successCreateAssignMappingLocation: boolean;
  errorCreateAssignMappingLocation: boolean;
  assignMappingLocationData: any;
  assignMappingLocationMessage: string;
  loadingUpdateAssignLead: boolean;
  successUpdateAssignLead: boolean;
  errorUpdateAssignLead: boolean;
  updateAssignLeadData: any;
  updateAssignLeadMessage: string;
  loadingDeleteLeads: boolean;
  successDeleteLeads: boolean;
  errorDeleteLeads: boolean;
  deleteLeadsData: any;
  deleteLeadsMessage: string;
  loadingArchiveLeads: boolean;
  successArchiveLeads: boolean;
  errorArchiveLeads: boolean;
  archiveLeadsData: any;
  archiveLeadsMessage: string;
  loadingForceCorporateDeals: boolean;
  successForceCorporateDeals: boolean;
  errorForceCorporateDeals: boolean;
  forceCorporateDealsData: any;
  forceCorporateDealsMessage: string;
  loadingUnarchiveLeads: boolean;
  successUnarchiveLeads: boolean;
  errorUnarchiveLeads: boolean;
  unarchiveLeadsData: any;
  unarchiveLeadsMessage: string;

  filterQuery: any;
  categoryType?: any;
  purchaseType?: any;

  loadingBulkAssingmentLeads: boolean;
  successBulkAssingmentLeads: boolean;
  errorBulkAssingmentLeads: boolean;
  messageBulkAssingmentLeads: string;
  bulkAsssignmentLeads?: BulkAssingmentLeadsInetrface;
}

const defaultState: DefaultStateI = {
  loading: false,
  loadingCreateAssignMappingLocation: false,
  successCreateAssignMappingLocation: false,
  errorCreateAssignMappingLocation: false,
  assignMappingLocationData: {},
  assignMappingLocationMessage: '',
  leads: {
    data: [],
  },
  loadingUpdateAssignLead: false,
  successUpdateAssignLead: false,
  errorUpdateAssignLead: false,
  updateAssignLeadData: {},
  updateAssignLeadMessage: '',
  loadingDeleteLeads: false,
  successDeleteLeads: false,
  errorDeleteLeads: false,
  deleteLeadsData: {},
  deleteLeadsMessage: '',
  loadingArchiveLeads: false,
  successArchiveLeads: false,
  errorArchiveLeads: false,
  archiveLeadsData: {},
  archiveLeadsMessage: '',
  loadingForceCorporateDeals: false,
  successForceCorporateDeals: false,
  errorForceCorporateDeals: false,
  forceCorporateDealsData: {},
  forceCorporateDealsMessage: '',
  loadingUnarchiveLeads: false,
  successUnarchiveLeads: false,
  errorUnarchiveLeads: false,
  unarchiveLeadsData: {},
  unarchiveLeadsMessage: '',

  filterQuery: {},
  categoryType: {
    data: [],
  },
  purchaseType: {
    data: [],
  },

  loadingBulkAssingmentLeads: false,
  successBulkAssingmentLeads: false,
  errorBulkAssingmentLeads: false,
  messageBulkAssingmentLeads: '',
  bulkAsssignmentLeads: {
    data: [],
    total_success: 0,
    total_failed: 0,
    total: 0,
  },
};

const leadsReducer = (state: DefaultStateI = defaultState, action: LeadsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_LEADS:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case GET_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_LEADS_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        lead_suggestions: action.payload,
      };
    case GET_LEADS_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ASSIGN_MAPPING_LOCATION:
      return {
        ...state,
        loadingCreateAssignMappingLocation: true,
        successCreateAssignMappingLocation: false,
        errorCreateAssignMappingLocation: false,
        assignMappingLocationMessage: '',
      };
    case CREATE_ASSIGN_MAPPING_LOCATION_SUCCESS:
      const updateLeads = {
        data: state.leads.data.map(lead => {
          if (lead.id === action.payload.id) {
            lead.lead_owner = action.payload.lead_owner;
            return lead;
          }

          return lead;
        }),
      };

      return {
        ...state,
        loadingCreateAssignMappingLocation: false,
        successCreateAssignMappingLocation: true,
        errorCreateAssignMappingLocation: false,
        leads: updateLeads,
        assignMappingLocationData: action.payload,
        assignMappingLocationMessage: action.message,
      };
    case CREATE_ASSIGN_MAPPING_LOCATION_FAILURE:
      return {
        ...state,
        loadingCreateAssignMappingLocation: false,
        errorCreateAssignMappingLocation: true,
        successCreateAssignMappingLocation: false,
        assignMappingLocationMessage: action.message,
      };
    case UPDATE_ASSIGN_LEAD:
      return {
        ...state,
        loadingUpdateAssignLead: true,
        successUpdateAssignLead: false,
        errorUpdateAssignLead: false,
        updateAssignLeadMessage: '',
      };
    case UPDATE_ASSIGN_LEAD_SUCCESS:
      const updateAssignLeads = {
        data: state.leads.data.map(lead => {
          if (lead.id === action.payload.id) {
            lead.lead_owner = action.payload.lead_owner;
            return lead;
          }

          return lead;
        }),
      };

      return {
        ...state,
        loadingUpdateAssignLead: false,
        successUpdateAssignLead: true,
        errorUpdateAssignLead: false,
        leads: updateAssignLeads,
        updateAssignLeadMessage: action.message,
        updateAssignLeadData: action.payload,
      };
    case UPDATE_ASSIGN_LEAD_FAILURE:
      return {
        ...state,
        loadingUpdateAssignLead: false,
        errorUpdateAssignLead: true,
        successUpdateAssignLead: false,
        updateAssignLeadMessage: action.message,
      };
    case DELETE_LEADS:
      return {
        ...state,
        loadingDeleteLeads: true,
        errorDeleteLeads: false,
        successDeleteLeads: false,
      };
    case DELETE_LEADS_SUCCESS:
      return {
        ...state,
        loadingDeleteLeads: false,
        errorDeleteLeads: false,
        successDeleteLeads: true,
        deleteLeadsData: action.payload,
      };
    case DELETE_LEADS_FAILURE:
      return {
        ...state,
        loadingDeleteLeads: false,
        errorDeleteLeads: true,
        successDeleteLeads: false,
        deleteLeadsMessage: action.message,
      };
    case ARCHIVE_LEADS:
      return {
        ...state,
        loadingArchiveLeads: true,
        errorArchiveLeads: false,
        successArchiveLeads: false,
      };
    case ARCHIVE_LEADS_SUCCESS:
      return {
        ...state,
        loadingArchiveLeads: false,
        errorArchiveLeads: false,
        successArchiveLeads: true,
        archiveLeadsData: action.payload,
      };
    case ARCHIVE_LEADS_FAILURE:
      return {
        ...state,
        loadingArchiveLeads: false,
        errorArchiveLeads: true,
        successArchiveLeads: false,
        archiveLeadsMessage: action.message,
      };
    case FORCE_CORPORATE_DEALS:
      return {
        ...state,
        loadingForceCorporateDeals: true,
        errorForceCorporateDeals: false,
        successForceCorporateDeals: false,
      };
    case FORCE_CORPORATE_DEALS_SUCCESS:
      return {
        ...state,
        loadingForceCorporateDeals: false,
        errorForceCorporateDeals: false,
        successForceCorporateDeals: true,
        forceCorporateDealsData: action.payload,
      };
    case FORCE_CORPORATE_DEALS_FAILURE:
      return {
        ...state,
        loadingForceCorporateDeals: false,
        errorForceCorporateDeals: true,
        successForceCorporateDeals: false,
        forceCorporateDealsMessage: action.message,
      };
    case UNARCHIVE_LEADS:
      return {
        ...state,
        loadingUnarchiveLeads: true,
        errorUnarchiveLeads: false,
        successUnarchiveLeads: false,
      };
    case UNARCHIVE_LEADS_SUCCESS:
      return {
        ...state,
        loadingUnarchiveLeads: false,
        errorUnarchiveLeads: false,
        successUnarchiveLeads: true,
        unarchiveLeadsData: action.payload,
      };
    case UNARCHIVE_LEADS_FAILURE:
      return {
        ...state,
        loadingUnarchiveLeads: false,
        errorUnarchiveLeads: true,
        successUnarchiveLeads: false,
        unarchiveLeadsMessage: action.message,
      };
    case UPDATE_FILTER_LEADS:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case GET_PRODUCT_CATEGORY:
      return {
        ...state,
      };
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryType: action.payload,
      };
    case GET_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
      };
    case GET_PURCHASE_TYPE:
      return {
        ...state,
      };
    case GET_PURCHASE_TYPE_SUCCESS:
      return {
        ...state,
        purchaseType: action.payload,
      };
    case GET_PURCHASE_TYPE_FAILURE:
      return {
        ...state,
      };
    case BULK_ASSIGNMENT_LEADS:
      return {
        ...state,
        loadingBulkAssingmentLeads: true,
        successBulkAssingmentLeads: false,
        errorBulkAssingmentLeads: false,
        messageBulkAssingmentLeads: '',
      };
    case BULK_ASSIGNMENT_LEADS_SUCCESS:
      const LeadsBulk = {
        data: action.payload.bulk_lead_assigns,
        total_success: action.payload.total_success,
        total_failed: action.payload.total_failed,
        total: action.payload.total,
      };

      return {
        ...state,
        loadingBulkAssingmentLeads: false,
        successBulkAssingmentLeads: true,
        errorBulkAssingmentLeads: false,
        bulkAsssignmentLeads: LeadsBulk,
      };
    case BULK_ASSIGNMENT_LEADS_FAILURE:
      return {
        ...state,
        loadingBulkAssingmentLeads: false,
        errorBulkAssingmentLeads: true,
        successBulkAssingmentLeads: false,
        messageBulkAssingmentLeads: action.message,
      };
    case BULK_ASSIGNMENT_LEADS_RESET:
      return {
        ...state,
        loadingBulkAssingmentLeads: false,
        errorBulkAssingmentLeads: false,
        successBulkAssingmentLeads: false,
        messageBulkAssingmentLeads: '',
        bulkAsssignmentLeads: {
          data: [],
          total_success: 0,
          total_failed: 0,
          total: 0,
        },
      };
    default:
      return state;
  }
};

export default leadsReducer;
