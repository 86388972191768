import LazyDelay from '../utils/lazy-delay';

import {
  ArchiveActiveOutlined,
  CustomerActiveOutlined,
  IncomingPaymentActiveOutlined,
  LeadsActiveOutlined,
  MyApprovalActiveOutlined,
  SettingsActiveOutlined,
} from '@components/icon/menus';

// Dashboard page
const DashboardPage = LazyDelay(import('../pages/dashboard/dashboard'));

// Leads Page
const LeadsPage = LazyDelay(import('../pages/leads/leads'));
const CustomersPage = LazyDelay(import('../pages/customers/customers'));
const ArchivedPage = LazyDelay(import('../pages/archived/archived'));

// MY Approval Page
const MyApprovalLeadsPage = LazyDelay(import('../pages/my-approval/leads/leads'));
const MyApprovalPaymentPage = LazyDelay(import('../pages/my-approval/payment/payment'));

// Settings
const SettingsProductsPage = LazyDelay(import('../pages/settings/products'));
const PackageAdjustmentPage = LazyDelay(import('../pages/settings/package-adjustment'));
const CompanyMappingPage = LazyDelay(import('../pages/settings/company-mapping'));
const AreaMappingPage = LazyDelay(import('../pages/settings/mapping/Area'));

// Incoming Payments
const IncomingPaymentsPage = LazyDelay(import('../pages/incoming-payment/incoming-payment'));

export const AppRoutes: any = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: DashboardPage,
    icon: (
      <img
        alt='dashboard'
        src='https://s3-ap-southeast-1.amazonaws.com/crm-internaltools-staging.99iddev.net/asset-fe/img/icon/Dashboard.svg'
      />
    ),
    menu: true,
  },
  {
    name: 'Leads',
    path: '/leads',
    component: LeadsPage,
    // icon: <img alt='lead' src='https://s3-ap-southeast-1.amazonaws.com/crm-internaltools-staging.99iddev.net/asset-fe/img/icon/Leads.svg' />,
    icon: <LeadsActiveOutlined />,
    menu: true,
  },
  {
    name: 'Customers',
    path: '/customers',
    component: CustomersPage,
    // icon: <img alt='customer' src='https://s3-ap-southeast-1.amazonaws.com/crm-internaltools-staging.99iddev.net/asset-fe/img/icon/Customer.svg' />,
    icon: <CustomerActiveOutlined />,
    menu: true,
  },
  {
    name: 'Incoming Payments',
    path: '/incoming-payments',
    component: IncomingPaymentsPage,
    icon: <IncomingPaymentActiveOutlined />,
    menu: true,
  },
  {
    name: 'Archive',
    path: '/archive/leads',
    component: ArchivedPage,
    icon: <ArchiveActiveOutlined />,
    menu: true,
  },
  {
    name: 'My Approval',
    path: '/my-approval/leads',
    component: MyApprovalLeadsPage,
    icon: <MyApprovalActiveOutlined />,
    menu: true,
  },
  {
    name: 'My Approval',
    path: '/my-approval/payment',
    component: MyApprovalPaymentPage,
    icon: <MyApprovalActiveOutlined />,
    menu: true,
  },
  {
    name: 'Settings',
    path: '/settings/product',
    component: SettingsProductsPage,
    icon: <SettingsActiveOutlined />,
    menu: true,
  },
  {
    name: 'Settings',
    path: '/settings/packageadjustment',
    component: PackageAdjustmentPage,
    icon: <SettingsActiveOutlined />,
    menu: true,
  },
  {
    name: 'Settings',
    path: '/settings/companyMapping',
    component: CompanyMappingPage,
    icon: <SettingsActiveOutlined />,
    menu: true,
  },
  {
    name: 'Settings',
    path: '/settings/areamapping',
    component: AreaMappingPage,
    icon: <SettingsActiveOutlined />,
    menu: true,
  },
];
