export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export type UserType = {
  user: any;
};

export interface UserLogin {
  type: typeof USER_LOGIN;
  error: boolean;
}

export interface UserLoginSuccess {
  type: typeof USER_LOGIN_SUCCESS;
  payload: any;
  messages: any;
}

export interface userLoginFailure {
  type: typeof USER_LOGIN_FAILURE;
  error: boolean;
  messages: any;
}

export interface UserLogout {
  type: typeof USER_LOGOUT;
}

export interface UserLogoutSuccess {
  type: typeof USER_LOGOUT_SUCCESS;
}

export interface userLogoutFailure {
  type: typeof USER_LOGOUT_FAILURE;
}

export type UserDispatchTypes = UserLogin | UserLoginSuccess | userLoginFailure | UserLogout | UserLogoutSuccess | userLogoutFailure;
