import { SalesAreaRequest, SettingAreaAllRequest, SettingAreaRequest } from '@services/settings/type/settings/mapping/area';
import ApiClient from '@utils/api-client';
import { ApiEndpoint } from '@utils/constant/api-enpoint.constant';

const { settings } = ApiEndpoint;
const { base, locations, region } = settings;

const getAllLocationRegionArea = (payload: SettingAreaAllRequest) =>
  ApiClient.withAuth().then(api => api.get(`${base}${locations.locationRegionArea}/`, payload, {}).then(result => result?.data));

const getSalesOptionArea = (payload: SalesAreaRequest) =>
  ApiClient.withAuth().then(api => api.get(`${base}${region.base}${region.responsibleagent}`, payload, {}).then(result => result?.data));

const createLocationRegionArea = (payload: SettingAreaRequest) =>
  ApiClient.withAuth().then(api => api.post(`${base}${locations.locationRegionArea}/`, payload, {}).then(result => result?.data));

const updateLocationRegionArea = (id: number, payload: SettingAreaRequest) =>
  ApiClient.withAuth().then(api => api.put(`${base}${locations.locationRegionArea}/${id}`, payload, {}).then(result => result?.data));

const deleteLocationRegionArea = (id: number) =>
  ApiClient.withAuth().then(api => api.delete(`${base}${locations.locationRegionArea}/${id}`, null).then(result => result?.data));

export { getAllLocationRegionArea, getSalesOptionArea, createLocationRegionArea, updateLocationRegionArea, deleteLocationRegionArea };
