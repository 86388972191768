export const GET_AGENCY = 'GET_AGENCY';
export const GET_AGENCY_SUCCESS = 'GET_AGENCY_SUCCESS';
export const GET_AGENCY_FAILURE = 'GET_AGENCY_FAILURE';
export const GET_AGENCY_BY_ID = 'GET_AGENCY_BY_ID';
export const GET_AGENCY_SUCCESS_BY_ID = 'GET_AGENCY_SUCCESS_BY_ID';
export const GET_AGENCY_FAILURE_BY_ID = 'GET_AGENCY_FAILURE_BY_ID';
export const GET_AGENCY_HEAD = 'GET_AGENCY_HEAD';
export const GET_AGENCY_HEAD_SUCCESS = 'GET_AGENCY_HEAD_SUCCESS';
export const GET_AGENCY_HEAD_FAILURE = 'GET_AGENCY_HEAD_FAILURE';

export interface GetAgency {
  type: typeof GET_AGENCY;
}

export interface GetAgencySuccess {
  type: typeof GET_AGENCY_SUCCESS;
  payload: any;
}

export interface GetAgencyFailure {
  type: typeof GET_AGENCY_FAILURE;
}

export interface GetAgencyById {
  type: typeof GET_AGENCY_BY_ID;
}

export interface GetAgencySuccessById {
  type: typeof GET_AGENCY_SUCCESS_BY_ID;
  payload: any;
}

export interface GetAgencyFailureById {
  type: typeof GET_AGENCY_FAILURE_BY_ID;
}

export interface GetAgencyHead {
  type: typeof GET_AGENCY_HEAD;
}

export interface GetAgencyHeadSuccess {
  type: typeof GET_AGENCY_HEAD_SUCCESS;
  payload: any;
}

export interface GetAgencyHeadFailure {
  type: typeof GET_AGENCY_HEAD_FAILURE;
}

export type AgencyDispatchTypes =
  | GetAgency
  | GetAgencySuccess
  | GetAgencyFailure
  | GetAgencyById
  | GetAgencySuccessById
  | GetAgencyFailureById
  | GetAgencyHead
  | GetAgencyHeadSuccess
  | GetAgencyHeadFailure;
