import React, { lazy } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import App from './pages/App';
import LoadingBar from './components/loading/loading';
import { Provider } from 'react-redux';
import { isLoggedIn } from './utils/storage';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/rootReducer';

const Login = lazy(() => import('./pages/signin/signin'));
const ConfirmationDiscount = lazy(() => import('./pages/confirmation/discount'));
/**
 *
 * @param  {any} { store }
 * @return
 * @author Adam Lesmana G.S <aelgees.dev@gmail.com>
 */
function BaseApp({ store }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <React.Suspense fallback={<LoadingBar loading />}>
            <Switch>
              <Route exact path='/' render={() => (isLoggedIn() ? <Redirect to='/leads' /> : <Redirect to='/auth/login' />)} />

              <Route path='/auth/login' component={Login} />
              <Route path='/approval/discount/:token' component={ConfirmationDiscount} />
              <App fallback={<LoadingBar loading />} />
            </Switch>
          </React.Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

export default BaseApp;
