import { treeify } from 'treeify-js';
import { AppRoutes } from '@routes/routes';
import { MenuInterface } from '../type/menu.type';

export const generateMenu: (data: MenuInterface[]) => MenuInterface[] = (data: MenuInterface[]) => {
  let result: MenuInterface[];
  if (data) {
    result = data.map(item => {
      const routeApp = AppRoutes.find(x => x.name === item.route_name);
      return {
        ...item,
        icon: routeApp?.icon,
      };
    });
  }
  return (
    (treeify(result, {
      parentId: 'parent_id',
      multi: true,
    }) as MenuInterface[]) || null
  );
};
