import {
  myApprovalType,
  MyApprovalDispatchTypes,
  GET_APPROVAL,
  GET_APPROVAL_SUCCESS,
  GET_APPROVAL_FAILURE,
  UPDATE_APPROVAL,
  UPDATE_APPROVAL_SUCCESS,
  UPDATE_APPROVAL_FAILURE,
  UPDATE_APPROVAL_FILTER,
  CREATE_APPROVAL,
  CREATE_APPROVAL_SUCCESS,
  CREATE_APPROVAL_FAILURE,
  REPORT_APPROVAL,
  REPORT_APPROVAL_SUCCESS,
  REPORT_APPROVAL_FAILURE,
} from './my-approval-types';

export interface DefaultStateI {
  loading: boolean;

  approval?: myApprovalType;
  history?: myApprovalType;
  lead_suggestions?: any;

  loadingUpdateApproval: boolean;
  successUpdateApproval: boolean;
  errorUpdateApproval: boolean;

  loadingCreateApproval: boolean;
  successCreateApproval: boolean;
  errorCreateApproval: boolean;

  errorMessage: any;
  filterQuery: any;
}

const defaultState: DefaultStateI = {
  loading: false,
  approval: {
    data: [],
  },
  history: {
    data: [],
  },
  loadingUpdateApproval: false,
  successUpdateApproval: false,
  errorUpdateApproval: false,
  loadingCreateApproval: false,
  successCreateApproval: false,
  errorCreateApproval: false,

  errorMessage: {},
  filterQuery: {},
};

const myapprovalReducer = (state: DefaultStateI = defaultState, action: MyApprovalDispatchTypes): DefaultStateI => {
  switch (action.type) {
    //Approval Discount
    case GET_APPROVAL:
      return {
        ...state,
        loading: true,
      };
    case GET_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        approval: action.payload,
      };
    case GET_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_APPROVAL_FILTER:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case UPDATE_APPROVAL:
      return {
        ...state,
        loadingUpdateApproval: true,
        successUpdateApproval: false,
        errorUpdateApproval: false,
      };
    case UPDATE_APPROVAL_SUCCESS:
      return {
        ...state,
        loadingUpdateApproval: false,
        successUpdateApproval: true,
        errorUpdateApproval: false,
        approval: action.payload,
      };
    case UPDATE_APPROVAL_FAILURE:
      return {
        ...state,
        loadingUpdateApproval: false,
        successUpdateApproval: false,
        errorUpdateApproval: true,
        errorMessage: {},
      };
    case CREATE_APPROVAL:
      return {
        ...state,
        loadingCreateApproval: true,
        successCreateApproval: false,
        errorCreateApproval: false,
      };
    case CREATE_APPROVAL_SUCCESS:
      return {
        ...state,
        loadingCreateApproval: false,
        successCreateApproval: true,
        errorCreateApproval: false,
        approval: action.payload,
      };
    case CREATE_APPROVAL_FAILURE:
      return {
        ...state,
        loadingCreateApproval: false,
        successCreateApproval: false,
        errorCreateApproval: true,
        errorMessage: action.message,
      };
    case REPORT_APPROVAL:
      return {
        ...state,
        loading: true,
      };
    case REPORT_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REPORT_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default myapprovalReducer;
