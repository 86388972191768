export const GET_AREA = 'GET_AREA';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';
export const GET_AREA_FAILURE = 'GET_AREA_FAILURE';

export type areaType = {
  data: any;
};

export interface GetArea {
  type: typeof GET_AREA;
}

export interface GetAreaSuccess {
  type: typeof GET_AREA_SUCCESS;
  payload: areaType;
}

export interface GetAreaFailure {
  type: typeof GET_AREA_FAILURE;
}

export type AreaDispatchTypes = GetArea | GetAreaFailure | GetAreaSuccess;
