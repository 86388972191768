export const LEAD_NAME = 'Leads Name';
export const LEAD_AGENCY = 'Agency';
export const LEAD_OWNER = 'Leads Owner';

export const GET_LEADS = 'GET_LEADS';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAILURE = 'GET_LEADS_FAILURE';
export const GET_LEADS_SUGGESTION_SUCCESS = 'GET_LEADS_SUGGESTION_SUCCESS';
export const GET_LEADS_SUGGESTION_FAILURE = 'GET_LEADS_SUGGESTION_FAILURE';
export const GET_LEADS_OWNER_FAILURE = 'GET_LEADS_OWNER_FAILURE';
export const GET_LEADS_OWNER_SUCCESS = 'GET_LEADS_OWNER_SUCCESS';

export const GET_LEADS_REPORT_SUCCESS = 'GET_LEADS_REPORT_SUCCESS';
export const GET_LEADS_REPORT_FAILURE = 'GET_LEADS_REPORT_FAILURE';

export const CREATE_ASSIGN_MAPPING_LOCATION = 'CREATE_ASSIGN_MAPPING_LOCATION';
export const CREATE_ASSIGN_MAPPING_LOCATION_SUCCESS = 'CREATE_ASSIGN_MAPPING_LOCATION_SUCCESS';
export const CREATE_ASSIGN_MAPPING_LOCATION_FAILURE = 'CREATE_ASSIGN_MAPPING_LOCATION_FAILURE';

export const UPDATE_ASSIGN_LEAD = 'UPDATE_ASSIGN_LEAD';
export const UPDATE_ASSIGN_LEAD_SUCCESS = 'UPDATE_ASSIGN_LEAD_SUCCESS';
export const UPDATE_ASSIGN_LEAD_FAILURE = 'UPDATE_ASSIGN_LEAD_FAILURE';

export const DELETE_LEADS = 'DELETE_LEADS';
export const DELETE_LEADS_SUCCESS = 'DELETE_LEADS_SUCCESS';
export const DELETE_LEADS_FAILURE = 'DELETE_LEADS_FAILURE';

export const ARCHIVE_LEADS = 'ARCHIVE_LEADS';
export const ARCHIVE_LEADS_SUCCESS = 'ARCHIVE_LEADS_SUCCESS';
export const ARCHIVE_LEADS_FAILURE = 'ARCHIVE_LEADS_FAILURE';

export const FORCE_CORPORATE_DEALS = 'FORCE_CORPORATE_DEALS';
export const FORCE_CORPORATE_DEALS_SUCCESS = 'FORCE_CORPORATE_DEALS_SUCCESS';
export const FORCE_CORPORATE_DEALS_FAILURE = 'FORCE_CORPORATE_DEALS_FAILURE';

export const FORCE_ALLOW_BUNDLE = 'FORCE_ALLOW_BUNDLE';
export const FORCE_ALLOW_BUNDLE_SUCCESS = 'FORCE_ALLOW_BUNDLE_SUCCESS';
export const FORCE_ALLOW_BUNDLE_FAILURE = 'FORCE_ALLOW_BUNDLE_FAILURE';

export const FORCE_VERIFY_LISITNG_MIGRATION = 'FORCE_VERIFY_LISITNG_MIGRATION';
export const FORCE_VERIFY_LISITNG_MIGRATION_SUCCESS = 'FORCE_VERIFY_LISITNG_MIGRATION_SUCCESS';
export const FORCE_VERIFY_LISITNG_MIGRATION_FAILURE = 'FORCE_VERIFY_LISITNG_MIGRATION_FAILURE';

export const UNARCHIVE_LEADS = 'UNARCHIVE_LEADS';
export const UNARCHIVE_LEADS_SUCCESS = 'UNARCHIVE_LEADS_SUCCESS';
export const UNARCHIVE_LEADS_FAILURE = 'UNARCHIVE_LEADS_FAILURE';

export const UPDATE_FILTER_LEADS = 'UPDATE_FILTER_LEADS';

export const GET_PRODUCT_CATEGORY = 'GET_PRODUCT_CATEGORY';
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS';
export const GET_PRODUCT_CATEGORY_FAILURE = 'GET_PRODUCT_CATEGORY_FAILURE';

export const GET_PURCHASE_TYPE = 'GET_PURCHASE_TYPE';
export const GET_PURCHASE_TYPE_SUCCESS = 'GET_PURCHASE_TYPE_SUCCESS';
export const GET_PURCHASE_TYPE_FAILURE = 'GET_PURCHASE_TYPE_FAILURE';

export const BULK_ASSIGNMENT_LEADS = 'BULK_ASSIGNMENT_LEADS';
export const BULK_ASSIGNMENT_LEADS_SUCCESS = 'BULK_ASSIGNMENT_LEADS_SUCCESS';
export const BULK_ASSIGNMENT_LEADS_FAILURE = 'BULK_ASSIGNMENT_LEADS_FAILURE';
export const BULK_ASSIGNMENT_LEADS_RESET = 'BULK_ASSIGNMENT_LEADS_RESET';

export type leadsType = {
  data: any;
};

export type BulkAssingmentLeadsInetrface = {
  data: any;
  total_success: number;
  total_failed: number;
  total: number;
};

export interface GetLeads {
  type: typeof GET_LEADS;
}

export interface GetLeadsSuccess {
  type: typeof GET_LEADS_SUCCESS;
  payload: leadsType;
}

export interface GetLeadsFailure {
  type: typeof GET_LEADS_FAILURE;
}

export interface GetLeadSuggestionSuccess {
  type: typeof GET_LEADS_SUGGESTION_SUCCESS;
  payload: leadsType;
}

export interface GetLeadSuggestionFailure {
  type: typeof GET_LEADS_SUGGESTION_FAILURE;
}

export interface GetLeadOwnerSuccess {
  type: typeof GET_LEADS_OWNER_SUCCESS;
}

export interface GetLeadOwnerFailure {
  type: typeof GET_LEADS_OWNER_FAILURE;
}
export interface GetLeadReportSuccess {
  type: typeof GET_LEADS_SUGGESTION_SUCCESS;
  payload: leadsType;
}
export interface GetLeadReportFailure {
  type: typeof GET_LEADS_SUGGESTION_FAILURE;
}

export interface CreateAssignMappingLocation {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION;
}

export interface CreateAssignMappingLocationSuccess {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION_SUCCESS;
  payload: any;
  message: string;
}

export interface CreateAssignMappingLocationFailure {
  type: typeof CREATE_ASSIGN_MAPPING_LOCATION_FAILURE;
  message: string;
}

export interface UpdateAssignLead {
  type: typeof UPDATE_ASSIGN_LEAD;
}

export interface UpdateAssignLeadSuccess {
  type: typeof UPDATE_ASSIGN_LEAD_SUCCESS;
  payload: any;
  message: string;
}

export interface UpdateAssignLeadFailure {
  type: typeof UPDATE_ASSIGN_LEAD_FAILURE;
  message: string;
}

export interface DeleteLeads {
  type: typeof DELETE_LEADS;
}

export interface DeleteLeadsSuccess {
  type: typeof DELETE_LEADS_SUCCESS;
  payload: any;
}

export interface DeleteLeadsFailure {
  type: typeof DELETE_LEADS_FAILURE;
  message: string;
}

export interface ArchiveLeads {
  type: typeof ARCHIVE_LEADS;
}

export interface ArchiveLeadsSuccess {
  type: typeof ARCHIVE_LEADS_SUCCESS;
  payload: any;
}

export interface ArchiveLeadsFailure {
  type: typeof ARCHIVE_LEADS_FAILURE;
  message: string;
}

export interface UnarchiveLeads {
  type: typeof UNARCHIVE_LEADS;
}

export interface UnarchiveLeadsSuccess {
  type: typeof UNARCHIVE_LEADS_SUCCESS;
  payload: any;
}

export interface UnarchiveLeadsFailure {
  type: typeof UNARCHIVE_LEADS_FAILURE;
  message: string;
}

export interface ForceCorporateDeals {
  type: typeof FORCE_CORPORATE_DEALS;
}

export interface ForceCorporateDealsSuccess {
  type: typeof FORCE_CORPORATE_DEALS_SUCCESS;
  payload: any;
}

export interface ForceCorporateDealsFailure {
  type: typeof FORCE_CORPORATE_DEALS_FAILURE;
  message: string;
}

export interface ForceAllowBundle {
  type: typeof FORCE_ALLOW_BUNDLE;
}

export interface ForceAllowBundleSuccess {
  type: typeof FORCE_ALLOW_BUNDLE_SUCCESS;
  payload: any;
}

export interface ForceAllowBundleFailure {
  type: typeof FORCE_ALLOW_BUNDLE_FAILURE;
  message: string;
}

export interface ForceVerifyListingMigration {
  type: typeof FORCE_VERIFY_LISITNG_MIGRATION;
}

export interface ForceVerifyListingMigrationSuccess {
  type: typeof FORCE_VERIFY_LISITNG_MIGRATION_SUCCESS;
  payload: any;
}

export interface ForceVerifyListingMigrationFailure {
  type: typeof FORCE_VERIFY_LISITNG_MIGRATION_FAILURE;
  message: string;
}

export interface UpdateFilterLeads {
  type: typeof UPDATE_FILTER_LEADS;
  payload: any;
}
export interface GetProductType {
  type: typeof GET_PRODUCT_CATEGORY;
}
export interface GetProductTypeSuccess {
  type: typeof GET_PRODUCT_CATEGORY_SUCCESS;
  payload: any;
}

export interface GetProductTypeFailure {
  type: typeof GET_PRODUCT_CATEGORY_FAILURE;
}

export interface GetPurchaseType {
  type: typeof GET_PURCHASE_TYPE;
}
export interface GetPurchaseTypeSuccess {
  type: typeof GET_PURCHASE_TYPE_SUCCESS;
  payload: any;
}

export interface GetPurchaseTypeFailure {
  type: typeof GET_PURCHASE_TYPE_FAILURE;
}

export interface BulkAssignmentLeads {
  type: typeof BULK_ASSIGNMENT_LEADS;
}
export interface BulkAssignmentLeadsSuccess {
  type: typeof BULK_ASSIGNMENT_LEADS_SUCCESS;
  payload: any;
}

export interface BulkAssignmentLeadsFailure {
  type: typeof BULK_ASSIGNMENT_LEADS_FAILURE;
  message: string;
}

export interface BulkAssignmentLeadsReset {
  type: typeof BULK_ASSIGNMENT_LEADS_RESET;
}

export type LeadsDispatchTypes =
  | GetLeads
  | GetLeadsFailure
  | GetLeadsSuccess
  | GetLeadSuggestionSuccess
  | GetLeadSuggestionFailure
  | GetLeadOwnerFailure
  | GetLeadOwnerSuccess
  | GetLeadReportSuccess
  | GetLeadReportFailure
  | CreateAssignMappingLocation
  | CreateAssignMappingLocationSuccess
  | CreateAssignMappingLocationFailure
  | UpdateAssignLead
  | UpdateAssignLeadSuccess
  | UpdateAssignLeadFailure
  | DeleteLeads
  | DeleteLeadsSuccess
  | DeleteLeadsFailure
  | ArchiveLeads
  | ArchiveLeadsSuccess
  | ArchiveLeadsFailure
  | ForceCorporateDeals
  | ForceCorporateDealsSuccess
  | ForceCorporateDealsFailure
  | ForceAllowBundle
  | ForceAllowBundleSuccess
  | ForceAllowBundleFailure
  | ForceVerifyListingMigration
  | ForceVerifyListingMigrationSuccess
  | ForceVerifyListingMigrationFailure
  | UnarchiveLeads
  | UnarchiveLeadsSuccess
  | UnarchiveLeadsFailure
  | UpdateFilterLeads
  | GetProductType
  | GetProductTypeFailure
  | GetProductTypeSuccess
  | GetPurchaseType
  | GetPurchaseTypeFailure
  | GetPurchaseTypeSuccess
  | BulkAssignmentLeads
  | BulkAssignmentLeadsFailure
  | BulkAssignmentLeadsSuccess
  | BulkAssignmentLeadsReset;
