import { Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import '@assets/css/sidebar-v2.scss';
import { useMenu } from '@redux/settings/hook/menu.hook';
import { FC } from 'react';
/**
 * * Sidebar Navigation
 * @param  {*} { data }
 * @return *
 * @author Dadi Rosadi <dadi@99.co>
 * @author Akmal Fuady <akml.fuady@gmail.com>
 * @author Adam Lesmana G.S <aelgees.dev@gmail.com>
 * @author Fajar SJ <fajarthea5@gmail.com>
 * @author thomasnkurniawan <thomas.kurniawan@99.co>
 * @author albirro <albirrofirdaus@gmail.com>
 */
interface SideNavigationInterface {
  syncNotifications: any;
}
const SideNavigation: FC<SideNavigationInterface> = ({ syncNotifications }: SideNavigationInterface) => {
  const { data: menus } = useMenu(true);
  let history = useHistory();
  const location = useLocation();
  const renderSubmenu = (data, type = '') => {
    let elementSubmenu = [];
    data.children?.map(submenu => {
      if (submenu.children.length > 0) {
        elementSubmenu.push(
          <Menu.SubMenu
            key={submenu.path}
            className='grand-sub'
            title={<span style={{ position: 'absolute', marginLeft: '20px' }}>{submenu.title}</span>}
          >
            {renderSubmenu(submenu, 'grandsubmenu')}
          </Menu.SubMenu>,
        );
      } else {
        elementSubmenu.push(
          <Menu.Item
            key={`${submenu.path}`}
            onClick={() => {
              syncNotifications();
              history.push(`${submenu.path}`);
            }}
            className='submenu-item-title'
          >
            <span className={`${type}`}>{submenu.title}</span>
          </Menu.Item>,
        );
      }
    });
    return elementSubmenu;
  };
  return (
    <div className='sidebar'>
      <div className='sidebar__logo'>
        <img alt='logo' src='https://s3-ap-southeast-1.amazonaws.com/crm-internaltools-staging.99iddev.net/asset-fe/img/logo.svg' />
        <span>CRM</span>
      </div>
      <Menu mode='inline' defaultSelectedKeys={[`${location.pathname}`]} selectedKeys={[`${location.pathname}`]} className='sidebar__menu'>
        {menus?.map(menu => {
          let data = [];
          if (menu.children.length > 0) {
            data.push(
              <Menu.SubMenu key={menu.path} icon={menu.icon} title={menu.title}>
                {renderSubmenu(menu)}
              </Menu.SubMenu>,
            );
          } else {
            data.push(
              <Menu.Item
                className='sidebar__menu-parent'
                key={menu.path}
                onClick={() => {
                  syncNotifications();
                  history.push(`${menu.path}`);
                }}
                icon={menu.icon}
              >
                {menu.title}
              </Menu.Item>,
            );
          }
          return data;
        })}
      </Menu>
    </div>
  );
};
export default SideNavigation;
