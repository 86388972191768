import ApiClient from '@utils/api-client';
import { ApiEndpoint } from '@utils/constant/api-enpoint.constant';

const { settings } = ApiEndpoint;
const {
  leads: {
    transactions: { schema },
  },
} = settings;
const getTransactionSchemaService = () => ApiClient.withAuth().then(api => api.get(schema, {}, {}).then(result => result?.data));
const updateTransactionSchemaService = (trxId: number, payload: any) =>
  ApiClient.withAuth().then(api => api.put(`/leads/transaction/${trxId}/transaction-schema`, payload, null).then(result => result?.data));

export { getTransactionSchemaService, updateTransactionSchemaService };
