export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_FAILURE = 'GET_AGENTS_FAILURE';
export const GET_AGENT_INFORMATION = 'GET_AGENT_INFORMATION';
export const GET_AGENT_INFORMATION_SUCCESS = 'GET_AGENT_INFORMATION_SUCCESS';
export const GET_AGENT_INFORMATION_FAILURE = 'GET_AGENT_INFORMATION_FAILURE';
export const CREATE_CONVERT_CUSTOMER_TO_LEAD = 'CREATE_CONVERT_CUSTOMER_TO_LEAD';
export const CREATE_CONVERT_CUSTOMER_TO_LEAD_SUCCESS = 'CREATE_CONVERT_CUSTOMER_TO_LEAD_SUCCESS';
export const CREATE_CONVERT_CUSTOMER_TO_LEAD_FAILURE = 'CREATE_CONVERT_CUSTOMER_TO_LEAD_FAILURE';
export const GET_CUSTOMER_ACTIVITY_HISTORY = 'GET_CUSTOMER_ACTIVITY_HISTORY';
export const GET_CUSTOMER_ACTIVITY_HISTORY_SUCCESS = 'GET_CUSTOMER_ACTIVITY_HISTORY_SUCCESS';
export const GET_CUSTOMER_ACTIVITY_HISTORY_FAILURE = 'GET_CUSTOMER_ACTIVITY_HISTORY_FAILURE';
export const GET_CUSTOMER_TRANSACTION_HISTORY = 'GET_CUSTOMER_TRANSACTION_HISTORY';
export const GET_CUSTOMER_TRANSACTION_HISTORY_SUCCESS = 'GET_CUSTOMER_TRANSACTION_HISTORY_SUCCESS';
export const GET_CUSTOMER_TRANSACTION_HISTORY_FAILURE = 'GET_CUSTOMER_TRANSACTION_HISTORY_FAILURE';

export const UPDATE_FILTER_CUSTOMERS = 'UPDATE_FILTER_CUSTOMERS';

export const CONVERT_TO_LEADS = 'CONVERT_TO_LEADS';
export const CONVERT_TO_LEADS_SUCCESS = 'CONVERT_TO_LEADS_SUCCESS';
export const CONVERT_TO_LEADS_FAILURE = 'CONVERT_TO_LEADS_FAILURE';

export const BULK_CONVERT_LEADS = 'BULK_CONVERT_LEADS';
export const BULK_CONVERT_LEADS_SUCCESS = 'BULK_CONVERT_LEADS_SUCCESS';
export const BULK_CONVERT_LEADS_FAILURE = 'BULK_CONVERT_LEADS_FAILURE';
export const BULK_CONVERT_LEADS_RESET = 'BULK_CONVERT_LEADS_RESET';

export type agentType = {
  data: any;
};

export type BulkConvertLeadsInetrface = {
  data: any;
  total_success: number;
  total_failed: number;
  total: number;
};

export interface GetAgents {
  type: typeof GET_AGENTS;
}

export interface GetAgentsSuccess {
  type: typeof GET_AGENTS_SUCCESS;
  payload: any;
}

export interface GetAgentsFailure {
  type: typeof GET_AGENTS_FAILURE;
}

export interface GetAgentInformation {
  type: typeof GET_AGENT_INFORMATION;
}

export interface GetAgentInformationSuccess {
  type: typeof GET_AGENT_INFORMATION_SUCCESS;
  payload: any;
}

export interface CreateConvertCustomerToLead {
  type: typeof CREATE_CONVERT_CUSTOMER_TO_LEAD;
}

export interface GetAgentInformationFailure {
  type: typeof GET_AGENT_INFORMATION_FAILURE;
  payload: any;
}

export interface CreateConvertCustomerToLeadSuccess {
  type: typeof CREATE_CONVERT_CUSTOMER_TO_LEAD_SUCCESS;
  message: string;
}

export interface CreateConvertCustomerToLeadFailure {
  type: typeof CREATE_CONVERT_CUSTOMER_TO_LEAD_FAILURE;
}

export interface GetCustomerActivityHistory {
  type: typeof GET_CUSTOMER_ACTIVITY_HISTORY;
}

export interface GetCustomerActivityHistorySuccess {
  type: typeof GET_CUSTOMER_ACTIVITY_HISTORY_SUCCESS;
  payload: any;
  message: string;
}

export interface GetCustomerActivityHistoryFailure {
  type: typeof GET_CUSTOMER_ACTIVITY_HISTORY_FAILURE;
  message: string;
}

export interface GetCustomerTransactionHistory {
  type: typeof GET_CUSTOMER_TRANSACTION_HISTORY;
}

export interface GetCustomerTransactionHistorySuccess {
  type: typeof GET_CUSTOMER_TRANSACTION_HISTORY_SUCCESS;
  payload: any;
  message: string;
}

export interface GetCustomerTransactionHistoryFailure {
  type: typeof GET_CUSTOMER_TRANSACTION_HISTORY_FAILURE;
  message: string;
}

export interface updateFilterCustomers {
  type: typeof UPDATE_FILTER_CUSTOMERS;
  payload: any;
}

export interface ConvertToLeads {
  type: typeof CONVERT_TO_LEADS;
}

export interface ConvertToLeadsSuccess {
  type: typeof CONVERT_TO_LEADS_SUCCESS;
  payload: any;
  message: string;
}

export interface ConvertToLeadsFailure {
  type: typeof CONVERT_TO_LEADS_FAILURE;
  message: string;
}

export interface BulkConvertLeads {
  type: typeof BULK_CONVERT_LEADS;
}
export interface BulkConvertLeadsSuccess {
  type: typeof BULK_CONVERT_LEADS_SUCCESS;
  payload: any;
}

export interface BulkConvertLeadsFailure {
  type: typeof BULK_CONVERT_LEADS_FAILURE;
  message: string;
}

export interface BulkConvertLeadsReset {
  type: typeof BULK_CONVERT_LEADS_RESET;
}

export type AgentDispatchTypes =
  | GetAgents
  | GetAgentsFailure
  | GetAgentsSuccess
  | GetAgentInformation
  | GetAgentInformationFailure
  | GetAgentInformationSuccess
  | CreateConvertCustomerToLead
  | CreateConvertCustomerToLeadSuccess
  | CreateConvertCustomerToLeadFailure
  | GetCustomerActivityHistory
  | GetCustomerActivityHistorySuccess
  | GetCustomerActivityHistoryFailure
  | GetCustomerTransactionHistory
  | GetCustomerTransactionHistorySuccess
  | GetCustomerTransactionHistoryFailure
  | updateFilterCustomers
  | ConvertToLeads
  | ConvertToLeadsSuccess
  | ConvertToLeadsFailure
  | BulkConvertLeads
  | BulkConvertLeadsSuccess
  | BulkConvertLeadsFailure
  | BulkConvertLeadsReset;
