export const GET_SEARCH_SUGGESTION = 'GET_SEARCH_SUGGESTION';
export const GET_SEARCH_SUGGESTION_SUCCESS = 'GET_SEARCH_SUGGESTION_SUCCESS';
export const GET_SEARCH_SUGGESTION_FAILURE = 'GET_SEARCH_SUGGESTION_FAILURE';

export type searchSuggestionType = {
  data: any;
};
export interface GetSearchSuggestion {
  type: typeof GET_SEARCH_SUGGESTION;
}

export interface GetSearchSuggestionSuccess {
  type: typeof GET_SEARCH_SUGGESTION_SUCCESS;
  payload: any;
}

export interface GetSearchSuggestionFailure {
  type: typeof GET_SEARCH_SUGGESTION_FAILURE;
}

export type SearchSuggestionDispatchTypes = GetSearchSuggestion | GetSearchSuggestionFailure | GetSearchSuggestionSuccess;
