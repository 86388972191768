import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState, RequestTypeInterface } from './type/type';
import { RootState } from '@redux/rootReducer';
import {
  AreaSalesOption,
  SalesAreaRequest,
  SettingAreaAllRequest,
  SettingAreaInterface,
  SettingAreaRequest,
  SettingAreaResponse,
} from './type/settings/mapping/area';
import {
  createLocationRegionArea,
  deleteLocationRegionArea,
  getAllLocationRegionArea,
  getSalesOptionArea,
  updateLocationRegionArea,
} from './helper/mapping/area.api';
import { PaginationInterface } from '@utils/interface/pagination.interface';

export const fetchMappingArea = createAsyncThunk<SettingAreaResponse, SettingAreaAllRequest>(
  'setting/area/get-all',
  async (payload, { rejectWithValue }) => {
    const response = await getAllLocationRegionArea(payload);
    if (response?.status_code !== 200) {
      rejectWithValue(response);
    }

    const result = {
      data: response?.data,
      pagination: {
        current: response?.meta?.page,
        pageSize: response?.meta?.page_size,
        total: response?.meta?.totalData,
      },
    } as SettingAreaResponse;

    return result || null;
  },
);

export const fetchAreasales = createAsyncThunk<AreaSalesOption[], SalesAreaRequest>(
  'setting/area/get-sales-option',
  async (payload, { rejectWithValue }) => {
    const response = await getSalesOptionArea(payload);
    if (response?.status_code !== 200) {
      rejectWithValue(response);
    }

    return (response?.data as AreaSalesOption[]) || null;
  },
);

export const createMappingArea = createAsyncThunk<RequestTypeInterface, SettingAreaRequest>(
  'setting/area/create',
  async (payload, { rejectWithValue }) => {
    try {
      const response: RequestTypeInterface = await createLocationRegionArea(payload);
      if (response?.status_code !== 200 || response?.status_code !== 201) {
        rejectWithValue(response);
      }
      return response as RequestTypeInterface;
    } catch (e: any) {
      rejectWithValue(e);
      return e;
    }
  },
);

export const updateMappingArea = createAsyncThunk<RequestTypeInterface, SettingAreaRequest>(
  'setting/area/update',
  async (payload, { rejectWithValue }) => {
    try {
      const body = {
        province_id: payload?.province_id,
        city_id: payload?.city_id,
        district_id: payload?.district_id,
        sales_id: payload?.sales_id,
      };
      const response: RequestTypeInterface = await updateLocationRegionArea(payload?.id, body);
      if (response?.status_code !== 200 || response?.status_code !== 201) {
        rejectWithValue(response);
      }
      return response as RequestTypeInterface;
    } catch (e: any) {
      rejectWithValue(e);
      return e;
    }
  },
);

export const deleteMappingArea = createAsyncThunk<RequestTypeInterface, number>(
  'setting/area/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response: RequestTypeInterface = await deleteLocationRegionArea(payload);
      if (response?.status_code !== 200 || response?.status_code !== 201) {
        rejectWithValue(response);
      }
      return response as RequestTypeInterface;
    } catch (e: any) {
      rejectWithValue(e);
      return e;
    }
  },
);

export const settingAreaMappingSlice = createSlice({
  name: 'setting/area',
  initialState: {
    status: undefined as RequestState,
    data: undefined as SettingAreaInterface[],
    detail: undefined as SettingAreaInterface,
    error: false as boolean,
    errorMessage: undefined as string,
    success: false as boolean,
    successMessage: undefined as string,
    loading: false as boolean,
    pagination: undefined as PaginationInterface,
    filter: undefined as SettingAreaAllRequest,
  },
  reducers: {
    afterError: (state, action) => {
      const { payload } = action;
      switch (payload) {
        case 'success':
          state.success = false;
          state.successMessage = undefined;
          break;
        case 'error':
          state.error = false;
          state.errorMessage = undefined;
          break;
        default:
          break;
      }
    },
    updateDetail: (state, action) => {
      const { payload } = action;
      state.detail = payload;
    },
    updateFilter: (state, action) => {
      const { payload } = action;
      state.filter = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMappingArea.pending, state => {
      state.status = 'pending';
      state.loading = true;
    });
    builder.addCase(fetchMappingArea.fulfilled, (state, action) => {
      const { data, pagination } = action.payload;
      state.status = 'fulfilled';
      state.data = data;
      state.pagination = pagination;
      state.loading = false;
    });
    builder.addCase(fetchMappingArea.rejected, state => {
      state.status = 'rejected';
      state.data = null;
      state.loading = false;
    });
    builder.addCase(createMappingArea.pending, state => {
      state.status = 'pending';
      state.loading = true;
      state.error = false;
      state.errorMessage = undefined;
    });
    builder.addCase(createMappingArea.fulfilled, (state, action) => {
      const { status_code, message }: any = action.payload;
      state.status = 'fulfilled';
      if (status_code === 200 || status_code === 201) {
        state.error = false;
        state.errorMessage = undefined;
        state.success = true;
        state.successMessage = message;
      } else {
        state.error = status_code !== 200;
        state.errorMessage = message || undefined;
      }
    });
    builder.addCase(createMappingArea.rejected, state => {
      state.status = 'rejected';
      state.data = null;
      state.loading = false;
    });
    builder.addCase(updateMappingArea.pending, state => {
      state.status = 'pending';
      state.loading = true;
      state.error = false;
      state.errorMessage = undefined;
    });
    builder.addCase(updateMappingArea.fulfilled, (state, action) => {
      const { status_code, message }: any = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      if (status_code === 200 || status_code === 201) {
        state.error = false;
        state.errorMessage = undefined;
        state.success = true;
        state.successMessage = message;
      } else {
        state.error = status_code !== 200;
        state.errorMessage = message || undefined;
      }
    });
    builder.addCase(updateMappingArea.rejected, state => {
      state.status = 'rejected';
      state.data = null;
      state.loading = false;
    });
    builder.addCase(deleteMappingArea.pending, state => {
      state.status = 'pending';
      state.loading = true;
      state.error = false;
      state.errorMessage = undefined;
    });
    builder.addCase(deleteMappingArea.fulfilled, (state, action) => {
      const { status_code, message }: any = action.payload;
      state.status = 'fulfilled';
      state.loading = false;
      if (status_code === 200 || status_code === 201) {
        state.error = false;
        state.errorMessage = undefined;
        state.success = true;
        state.successMessage = message;
      } else {
        state.error = status_code !== 200;
        state.errorMessage = message || undefined;
      }
    });
    builder.addCase(deleteMappingArea.rejected, state => {
      state.status = 'rejected';
      state.data = null;
      state.loading = false;
    });
  },
});

export const selectMappingArea = (state: RootState) => state.mapping.area.data;
export const selectArea = (state: RootState) => state.mapping.area.detail;
export const selectMappingAreaStatus = (state: RootState) => state.mapping.area.status;
export const selectMappingAreaLoading = (state: RootState) => state.mapping.area.loading;
export const selectMappingAreaError = (state: RootState) => state.mapping.area.error;
export const selectMappingAreaErrorMessage = (state: RootState) => state.mapping.area.errorMessage;
export const selectMappingAreaSuccess = (state: RootState) => state.mapping.area.success;
export const selectMappingAreaSuccessMessage = (state: RootState) => state.mapping.area.successMessage;
export const selectAreaPagination = (state: RootState) => state.mapping.area.pagination;
export const selectAreaFilter = (state: RootState) => state.mapping.area.filter;
export const { actions, reducer } = settingAreaMappingSlice;
export const { afterError, updateDetail, updateFilter } = actions;
export default reducer;
