import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth-reducer';
import leadsReducer from './leads/leads-reducer';
import detailLeadReducer from './detail-lead/lead-reducer';
import agentsReducer from './customers/agent/agent-reducer';
import areaReducer from './area/area-reducer';
import reducer from './customers/search-suggestion/reducer';
import appPermissionReducer from './app/permission/permission-reducer';
import appConfirmReducer from './app/confirm/confirmation-reducer';
import settingsReducer from './settings/setting-reducer';
import agenciesReducer from './agency/agency-reducer';
import locationReducer from './location/location-reducer';
import archivedLeadsReducer from './archive/archive-reducer';
import myApprovalReducer from './my-approval/my-approval-reducer';
import myApprovaSearchReducer from './customers/search-suggestion/reducer';
import settingAreaMappingSlice from '../services/settings/mapping-area.slice';
import settingComapnyMappingSlice from '../services/settings/mapping-company.slice';
import { EnhancedStore, ThunkAction, Action, configureStore, combineReducers } from '@reduxjs/toolkit';
import menuSlice from '../services/settings/menu.slice';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import leadsSlice from '../services/leads/leads.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const reducers = combineReducers({
  auth: authReducer,
  leads: leadsReducer,
  agent: agentsReducer,
  detailLead: detailLeadReducer,
  area: areaReducer,
  searchSuggestion: reducer,
  appMenus: menuSlice,
  appPermissions: appPermissionReducer,
  settings: settingsReducer,
  agency: agenciesReducer,
  locations: locationReducer,
  archivedLeads: archivedLeadsReducer,
  myApproval: myApprovalReducer,
  searchSuggestionApproval: myApprovaSearchReducer,
  appConfirm: appConfirmReducer,
  mapping: combineReducers({
    area: settingAreaMappingSlice,
    company: settingComapnyMappingSlice,
  }),
  leadsV2: leadsSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const rootReducer: EnhancedStore<any> = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type AppDispatch = typeof rootReducer.dispatch;
export type RootState = ReturnType<typeof rootReducer.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const persistor = persistStore(rootReducer);
